import { ApiGame } from "../../../types/apigame";
import Game from "../../game";
import IGameFog from "./igamefog";

export default class GameFogNone implements IGameFog {
    constructor(protected g: Game) {}

    public hideFogCountries(game: ApiGame, seatNumber: number): void {
        // no op
    }
}
