import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useCallback, useState } from "react";
import SettingsDialog from "./settings/settingsdialog";

export default function NavigationSettingsButton(): JSX.Element {
    const [isSettingsDialogOpen, setIsSettingsDoalogOpen] = useState<boolean>(
        false,
    );

    const closeSettingsDialog = useCallback(
        () => setIsSettingsDoalogOpen(false),
        [setIsSettingsDoalogOpen],
    );

    const openSettingsDialog = useCallback(
        () => setIsSettingsDoalogOpen(true),
        [setIsSettingsDoalogOpen],
    );

    return <>
        <Button
            icon={IconNames.Cog}
            minimal={true}
            onClick={openSettingsDialog}
        />
        <SettingsDialog
            isOpen={isSettingsDialogOpen}
            onClose={closeSettingsDialog}
        />
    </>;
}
