import { UserProfile } from "../../src/models/socket/profile";
import PlayerAvatar from "./avatar";
import styles from './userprofiledialogcomponent.module.css';
import UserProfileDialogStats from "./userprofiledialogstats";
import GameGamesList from "../game/gameslist";
import UserProfileDialogPresence from "./userprofiledialogpresence";

type Props = {
    profile: UserProfile;
    userId: number;
}

export default function UserProfileDialogComponent(
    {
        profile,
        userId,
    }: Props
) {
    return <div className={styles.body}>
        <PlayerAvatar
            src={profile.user.avatar}
            title={profile.user.name}
        />
        <div className={styles.name}>
            {profile.user.name}
        </div>
        <UserProfileDialogPresence status={profile.presence} />
        <div className={styles.sectionHeader}>
            Stats
        </div>
        <UserProfileDialogStats stats={profile.stats} />
        <div className={styles.sectionHeader}>
            Active Games
        </div>
        <GameGamesList
            games={profile.activeGames}
            userId={userId}
        />
        <div className={styles.sectionHeader}>
            Completed Games
        </div>
        <GameGamesList
            games={profile.completedGames}
            userId={userId}
        />
    </div>;
}
