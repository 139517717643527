import getItemById from "../../../src/util/getItemById";
import GameState from "../../../src/enum/gamestate";
import { ReactSVG } from "react-svg";
import styles from './gameslistgame.module.css';
import { ApiGame } from "../../../src/types/apigame";

type Props = {
    game: ApiGame;
    userId: number;
}

export default function GameGamesListJewel(
    {
        game,
        userId,
    }: Props,
) {
    const activeSeats = game.activeSeats;
    const mySeatObject = getItemById(activeSeats, userId, 'userId');

    let icon = null;
    if (game.state === GameState.Complete && mySeatObject) {
        icon = <ReactSVG
            className={styles.crown}
            src="/images/crown-icon.svg"
        />;
    }

    return icon;
}
