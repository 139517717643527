import { Popover, PopoverPosition } from "@blueprintjs/core";
import { useSession } from "next-auth/react";
import PlayerAvatar from "../user/avatar";
import SButton from "../global/sbutton";
import React from "react";
import { Theme } from "../global/theme";
import LoggedInMenu from "./loggedinmenu";
import LoggedInUserPresenceIndicator from "./loggedinuserpresenceindicator";

export default function NavbarLoggedInContainer() {
    const { data: session } = useSession();
    const user = session.user;

    const icon = <PlayerAvatar
        src={user.avatar}
        style={{ maxHeight: '16px' }}
    />;

    const text = <React.Fragment>
        {user.name}
        &nbsp;
        <LoggedInUserPresenceIndicator theme={Theme.Dark} />
    </React.Fragment>;

    return (
        <Popover
            content={<LoggedInMenu />}
            position={PopoverPosition.BOTTOM_RIGHT}
        >
            <SButton icon={icon} text={text} />
        </Popover>
    );
}
