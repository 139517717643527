import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ServerFlags } from '../../src/types/flags';
import { useSocket } from './socket';
import { ClientRequests } from '../../src/enum/socket';

const ServerFlagsContext = createContext<ServerFlags>(null);

export function ServerFlagsProvider({ children }) {
    const { socket } = useSocket();
    const [serverFlags, setServerFlags] = useState<ServerFlags>(null);

    const fetchServerFlags = useCallback(
        async () => {
            if (!socket) {
                return;
            }

            try {
                const newServerFlags = await socket.emitWithAck(
                    ClientRequests.GetServerFlags,
                );

                setServerFlags(newServerFlags);
            } catch (err) {
                console.error('Error fetching server flags', err);
            }
        },
        [socket, setServerFlags],
    );

    useEffect(
        () => {
            fetchServerFlags();
        },
        [fetchServerFlags],
    );

    return (
        <ServerFlagsContext.Provider value={serverFlags}>
            {children}
        </ServerFlagsContext.Provider>
    );
}

export function useServerFlags() {
    return useContext(ServerFlagsContext);
}
