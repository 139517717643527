import { BonusCalculation } from "../action";
import GameModeStandard from "./standard";
import { CombatMode, CombatStats } from "./igamemode";
import { ApiCountry, ApiSeat } from "../../../types/apigame";

export const CAPITAL_BONUS_UNITS_START_TURN = 2;

export default class GameModeCapitals extends GameModeStandard {
    override getGameModeName(): string {
        return 'Capitals';
    }

    override getGameModeDescription(): string {
        return 'Control all the capitals to win!';
    }

    override getGameModeIconName(): string {
        return 'capitals';
    }

    override getBonusCalculationOnTurnStart(seatNumber?: number): BonusCalculation {
        if (!(seatNumber >= 0)) {
            seatNumber = this.gameController.game.turn;
        }

        const result = super.getBonusCalculationOnTurnStart(seatNumber);
        const countries = this.gameController.getCountriesForSeat(seatNumber);

        for (const country of countries) {
            if (this.isCountryIdCapital(country.countryId)) {
                result.capitalBonus += CAPITAL_BONUS_UNITS_START_TURN;
            }
        }

        return result;
    }

    override getCombatStats(countryId: number, mode: CombatMode): CombatStats {
        const result = super.getCombatStats(countryId, mode);

        if (this.isCountryIdCapital(countryId) && mode === CombatMode.Defend) {
            ++result.maxUnits;
        }

        return result;
    }

    public getAllCapitalCountries(): ApiCountry[] {
        return this.gameController.game.countries
            .filter((country) => country.extraData?.isCapital);
    }

    public isCountryIdCapital(countryId: number): boolean {
        const country = this.gameController.getCountryById(countryId);
        return country.extraData?.isCapital;
    }
    
    override isGameOver(countryToExclude?: ApiCountry): boolean {
        // check if all other players are eliminated
        if (super.isGameOver(countryToExclude)) {
            return true;
        }

        // make sure capitals exist
        const capitals = this.getAllCapitalCountries();

        const currentSeatObject = this.gameController.getSeatObjectBySeatNumber(
            this.gameController.game.turn,
        );

        // iterate over all capitals
        for (const capitalCountry of capitals) {
            // ignore country to exclude
            if (capitalCountry.countryId === countryToExclude?.countryId) {
                continue;
            }

            // check if the capital is owned by the current player
            if (capitalCountry.seatNumber !== currentSeatObject.seatNumber) {
                // if not, they haven't won
                return false;
            }
        }

        // if we get to here, current player has all capitals
        return true;
    }
}
