import { useCallback } from "react";
import Game from "../../../src/models/game";
import { ApiGame } from "../../../src/types/apigame"
import { ReactSVG } from "react-svg";
import styles from './gameslistgame.module.css';

type Props = {
    game: ApiGame;
}

export default function GamesListGameModeIcon(
    {
        game,
    }: Props,
): JSX.Element {
    const g = new Game(game);

    const beforeInjection = useCallback(
        (svg: SVGSVGElement) => {
            svg.classList.add(styles.modeIcon);
        },
        [],
    );

    return <ReactSVG
        beforeInjection={beforeInjection}
        className={['bp5-icon', styles.modeIcon].join(' ')}
        src={`/images/gamemodes/${g.gameMode.getGameModeIconName()}.svg`}
        title={g.gameMode.getGameModeName()}
        wrapper='span'
    />;
}
