import { ApiCountry, ApiSeat } from "../../../types/apigame";
import GameModeStandard from "./standard";

type PercentLeader = {
    leaderSeatObject: ApiSeat;
    leaderCountries: number;
}

export default class GameModePercent extends GameModeStandard {
    override getGameModeName(): string {
        const percent = this.percentNeeded;
        return `${percent}% Domination`;
    }

    override getGameModeDescription(): string {
        const percent = this.percentNeeded;
        return `Control ${percent}% of countries to win!`;
    }

    override getGameModeIconName(): string {
        return 'percent';
    }

    get percentNeeded(): number { return 70; }

    override isGameOver(countryToExclude?: ApiCountry): boolean {
        // check if all other players are eliminated
        if (super.isGameOver(countryToExclude)) {
            return true;
        }

        const game = this.gameController.game;
        const countries = game.countries;
        const turn = game.turn;

        let count = 0;

        for (const country of countries) {
            if (
                this.gameController.gameMode.areAllies(country.seatNumber, turn)
                || country.countryId === countryToExclude?.countryId
            ) {
                ++count;
            }
        }

        return count / countries.length >= (this.percentNeeded * 0.01);
    }

    public getNumCountriesToWin(): number {
        const floatPercent = this.percentNeeded * 0.01;
        const minCountriesNeededDecimal = this.gameController.game.countries.length * floatPercent;
        return Math.ceil(minCountriesNeededDecimal);
    }

    public getTotalMap(): Map<number, number> {
        const game = this.gameController.game;
        const totalMap = new Map<number, number>();

        for (const country of game.countries) {
            const seatNumber = country.seatNumber;

            if (seatNumber < 0) {
                continue;
            }

            const total = totalMap.get(seatNumber) ?? 0;
            totalMap.set(seatNumber, total + 1);
        }

        return totalMap;
    }

    public getLeader(): PercentLeader {
        const game = this.gameController.game;
        const totalMap = this.getTotalMap();

        let leaderSeatObject: ApiSeat = null;
        let leaderCountries: number = null;

        for (const seat of game.seats) {
            const seatNumber = seat.seatNumber;
            const total = totalMap.get(seatNumber);

            if (total <= 0) {
                continue;
            }

            if (!leaderCountries || total > leaderCountries) {
                leaderSeatObject = seat;
                leaderCountries = total;
            }
        }

        return {leaderSeatObject, leaderCountries};
    }
}
