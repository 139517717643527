import { Tag } from '@blueprintjs/core';
import { ApiGame } from '../../../src/types/apigame';
import styles from './gameslistgame.module.css';

type Props = {
    game: ApiGame;
};

export default function GamesListMap(
    {
        game,
    }: Props,
): JSX.Element {
    const map = game.map;

    return <div className={styles.map}>
        <picture>
            <img
                className={styles.mapImage}
                src={`/api/map/getthumbnail?mapId=${game.mapId}`}
                alt={map.name}
                draggable={false}
            />
        </picture>
    </div>;
}
