import { Intent, Spinner, SpinnerSize, Tag } from "@blueprintjs/core";
import { useActiveGamesProvider } from "../global/activegamesprovider";
import styles from './navigationregular.module.css';

export default function NewGameCount(): JSX.Element {
    const { gameIds, isLoading } = useActiveGamesProvider();

    if (isLoading) {
        return <Spinner size={SpinnerSize.SMALL} style={{ display: 'inline-flex' }} />;
    }

    const numGames = gameIds?.length ?? 0;
    const className = numGames > 0
        ? styles.gameIndicatorOn
        : styles.gameIndicatorOff;

    return <Tag className={className} intent={Intent.SUCCESS} round={true}>
        {numGames}
    </Tag>
}
