import { Navbar, NavbarDivider, NavbarGroup, NavbarHeading } from '@blueprintjs/core';
import NavbarAccountButtonContainer from './navbaraccountbutton';
import NavigationSettingsButton from './settingsbutton';
import NewGameCount from './newgamecount';
import { IconNames } from '@blueprintjs/icons';
import SAnchorButton from '../global/sanchorbutton';
import { useSession } from 'next-auth/react';
import React from 'react';

export default function NavigationRegular() {
    const { data: session } = useSession();
    const user = session?.user;

    let adminElement: React.ReactNode | null;
    if (user?.isAdmin) {
        adminElement = <React.Fragment>
            <NavbarDivider />
            <SAnchorButton
                href="/admin"
                minimal={true}
            >
                ADMIN
            </SAnchorButton>
        </React.Fragment>;
    }

    return (
        <Navbar className="bp5-dark" style={{ position: 'fixed' }}>
            <NavbarGroup align='left'>
                <NavbarHeading>
                    <SAnchorButton
                        href="/"
                        minimal={true}
                    >S•P•Q•R
                    </SAnchorButton>
                </NavbarHeading>
                <NavbarDivider />
                <SAnchorButton
                    href="/game"
                    minimal={true}
                >
                    <div style={{ display: 'flex' }}>
                        GAMES&nbsp;
                        <NewGameCount />
                    </div>
                </SAnchorButton>
                <NavbarDivider />
                <SAnchorButton
                    href="/map"
                    minimal={true}
                >
                    MAPS
                </SAnchorButton>
                {adminElement}
            </NavbarGroup>
            <NavbarGroup
                align='right'
            >
                <SAnchorButton
                    href='/sponsor'
                    minimal={true}
                    title='Sponsor SPQR'
                >Sponsor</SAnchorButton>
                <SAnchorButton
                    href='https://github.com/spqr-game/spqr-issues/issues/new'
                    target='_blank'
                    minimal={true}
                    icon={IconNames.Bug}
                    title='Report a Bug'
                />
                <SAnchorButton
                    href="/stats"
                    minimal={true}
                    icon={IconNames.TIMELINE_LINE_CHART}
                    title='View Site Statistics'
                />
                <NavigationSettingsButton />
                <NavbarAccountButtonContainer />
            </NavbarGroup>
        </Navbar>
    );
}
