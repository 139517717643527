import fetchPost from "../util/fetchpost"

export function homepageRegister(
    email: string,
    password: string,
    name: string,
): Promise<any> {
    return fetchPost(
        '/api/user/register',
        { email, password, name },
    );
}

export function homepageForgotPassword(
    email: string,
): Promise<any> {
    return fetchPost(
        '/api/user/sendforgotpassword',
        { email },
    );
}
