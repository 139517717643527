'use client';

import { Alignment, Button, IconName, Intent, MaybeElement } from "@blueprintjs/core";
import { FocusEvent, MouseEvent, ReactNode, StyleHTMLAttributes } from "react";
import styles from './sbutton.module.css'

export interface SButtonProps {
    id?: string;
    active?: boolean;
    alignText?: Alignment;
    children?: ReactNode;
    className?: string;
    disabled?: boolean;
    fill?: boolean;
    icon?: IconName | MaybeElement;
    intent?: Intent;
    large?: boolean;
    loading?: boolean;
    minimal?: boolean;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    onFocus?: (event: FocusEvent<HTMLElement>) => void;
    outlined?: boolean;
    placeholder?: string;
    rightIcon?: IconName | MaybeElement;
    small?: boolean;
    style?: {[key: string]: any};
    text?: ReactNode;
    title?: string;
    type?: 'submit' | 'reset' | 'button';
}

export default function SButton(props: SButtonProps) {
    const buttonProps = Object.assign({}, {
        alignText: Alignment.CENTER,
        className: '',
        loading: false,
        type: 'button',
    }, props);
    buttonProps.className = `${styles.sbutton} ` + buttonProps.className;
    return <Button {...buttonProps} />;
}
