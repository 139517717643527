import { SetStateAction } from "react";
import { ApiGame } from "../types/apigame";
import fetchPost from "../util/fetchpost";
import { adjectives } from "../util/adjectives";
import { nouns } from "../util/nouns";
import { GameChatChannel, GamePrivacy } from "../enum/game";
import { BotDifficulty } from "../server/models/game/bot/enum/difficulty";
import { UnitMap } from "../types/map";

export type ErrorResponse = {
    status: string;
    message: string;
};

export async function gamePlayAddBot(
    gameId: number,
): Promise<any> {
    return fetchPost(
        '/api/game/addbot',
        { gameId },
    );
};

export async function gameAssignTeams(
    id: number,
    teams: any,
): Promise<any> {
    return fetchPostGame(
        id,
        '/api/game/assignteams',
        { id, teams },
    );
}

export async function gameCreate(
    mapId: number,
    maxSeats?: number,
    privacy?: GamePrivacy,
): Promise<any> {
    return fetchPost(
        '/api/game/create',
        {
            map: mapId,
            maxSeats,
            privacy,
        },
    );
}

export async function gameRematch(
    gameId: number,
): Promise<any> {
    return fetchPost(
        '/api/game/rematch',
        { gameId },
    );
}

export async function gameGet(
    id: number,
): Promise<any> {
    const result = await fetch(`/api/game/getgame?id=${id}`);
    const json: SetStateAction<ApiGame> = await result.json();

    return json;
}

export async function gamePlayContinuousAttack(
    id: number,
    fromCountryId: number,
    toCountryId: number,
): Promise<any> {
    return fetchPostGame(
        id,
        '/api/game/continuousattack',
        { id, fromCountryId, toCountryId, },
    );
}

export async function gamePlayFortify(
    id: number,
    fortifies: any[],
): Promise<ApiGame | ErrorResponse> {
    return fetchPostGame(
        id,
        '/api/game/fortify',
        { id, fortifies },
    );
};

export interface QueryUser {
    id: number;
    name: string;
};

export async function gameQueryUser(
    query: string,
    gameId: number,
): Promise<QueryUser[]> {
    const json = await fetchPost(
        '/api/user/listusers',
        { query, gameId }
    );
    
    if (json.status !== 'ok') {
        throw new Error('bad status');
    }

    return json.users;
};

export async function gamePlayJoin(
    id: number,
    color: number,
    name: string,
): Promise<any> {
    return fetchPost(
        '/api/game/join',
        { color, name, id },
    );
};

export async function gamePlayDeclineInvite(
    id: number,
): Promise<any> {
    return fetchPost(
        '/api/game/declineinvite',
        { id },
    );
};

export async function gamePlayPlaceUnits(
    id: number,
    unitMap: UnitMap,
): Promise<any> {
    return fetchPostGame(
        id,
        '/api/game/placeunits',
        { id, unitMap },
    );
};

export async function gamePlayRemovePlayer(
    id: number,
    seatIdToRemove: number,
    userIdToRemove?: number,
): Promise<any> {
    return fetchPost(
        '/api/game/removeplayer',
        { id, seatIdToRemove, userIdToRemove },
    );
};

export async function gamePlaySetName(
    gameId: number,
    gameName: string,
): Promise<any> {
    return fetchPost(
        '/api/game/setname',
        { gameId, gameName },
    );
};


export async function gamePlayTransferUnits(
    id: number,
    units: number,
): Promise<any> {
    return fetchPostGame(
        id,
        '/api/game/transferunits',
        { id, units },
    );
};

export async function gamePlayStart(
    id: number,
    rules: any,
    teams: any,
): Promise<any> {
    return fetchPost(
        '/api/game/start',
        { id, rules, teams },
    );
};

export async function gamePlaySetBotDifficulty(
    gameId: number,
    botId: number,
    difficulty: BotDifficulty,
): Promise<any> {
    return fetchPostGame(
        gameId,
        '/api/game/setbotdifficulty',
        { gameId, botId, difficulty },
    );
};


export async function gamePlaySurrender(
    id: number,
): Promise<any> {
    return fetchPostGame(
        id,
        '/api/game/surrender',
        { id },
    );
};

export async function gamePlayTurnIn(
    id: number,
    cardIds: number[],
): Promise<any> {
    return fetchPostGame(
        id,
        '/api/game/turnin',
        { id, cardIds },
    );
};

export async function gameUndoPlaceUnits(
    id: number,
): Promise<any> {
    return fetchPostGame(
        id,
        '/api/game/undoplaceunits',
        { id },
    );
};

export async function gameUndoTransferUnits(
    id: number,
): Promise<any> {
    return fetchPostGame(
        id,
        '/api/game/undotransferunits',
        { id },
    );
};

export function generateRandomName(): string {
    const adjectiveIndex = Math.floor(adjectives.length * Math.random());
    const nounIndex = Math.floor(nouns.length * Math.random());

    return `${adjectives[adjectiveIndex]} ${nouns[nounIndex]}`;
}

export async function gameGetGames(
): Promise<any[]> {
    return fetchPost('/api/game/getgames', {});
}

/**
 * Clients receive a full game object from POST calls
 * (like attack).
 *
 * It's entirely possible that since this payload is so
 * large, the client could receive a notification that
 * there is a new game event before the API call finishes,
 * and would fire off a redundant getGame call.
 *
 * We should rewrite this, but this is the workaround for now.
 */
const gameFetchMap = {};

async function fetchPostGame(
    gameId: number,
    url: string,
    body: any,
): Promise<any> {
    gameFetchMap[gameId] = true;

    try {
        const response = await fetchPost(url, body);
        return response;
    } catch (err) {
        throw err;
    } finally {
        gameFetchMap[gameId] = false;
    }
}

export function isFetchingPostGame(id: number): boolean {
    return gameFetchMap[id] === true;
}
