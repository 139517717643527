import { Checkbox } from "@blueprintjs/core";
import { RuleBoolean } from "../../../src/types/rule";

type Props = {
    isDisabled?: boolean;
    isEditable: boolean;
    onChange: (any) => void;
    rule: RuleBoolean;
    value: any;
};

export default function GameRuleBoolean({
    isDisabled,
    isEditable,
    onChange,
    rule,
    value,
}: Props) {
    if (!isEditable) {
        return <div>{String(value)}</div>
    }

    return <Checkbox
        disabled={isDisabled}
        id={rule.key}
        onChange={(e) => onChange((e.target as any).checked)}
        checked={value}
    />
}
