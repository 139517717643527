export enum ThumbnailType {
    Icon = 0,
    Card = 1,
};

type ThumbnailConfiguration = {
    id: number;
    width: number;
    height: number;
    alpha?: number;
}

export const THUMBNAIL_CONFIGURATIONS: { [key: string]: ThumbnailConfiguration } = {
    [ThumbnailType.Icon]: {
        id: 0,
        width: 128,
        height: 128,
    },
    [ThumbnailType.Card]: {
        id: 1,
        width: 480,
        height: 480,
        alpha: 0.15,
    },
};
