import { SocketState, useSocket } from "../global/socket";
import { PresenceStatusType } from "../../src/server/socket/types";
import React from "react";
import PresenceIndicator from "../global/presenceindicator";
import { Theme, useTheme } from "../global/theme";

type Props = {
    className?: string;
    theme?: Theme;
}

export default function LoggedInUserPresenceIndicator(
    {
        className,
        theme,
    }: Props,
) {
    const { theme: currentTheme } = useTheme();
    const { state } = useSocket();

    let presenceStatusType;

    switch (state) {
        case SocketState.CONNECTED:
            presenceStatusType = PresenceStatusType.Online;
            break;

        case SocketState.CONNECTING:
            presenceStatusType = PresenceStatusType.Connecting;
            break;

        case SocketState.DISCONNECTED:
            presenceStatusType = PresenceStatusType.Error;
            break;

        default:
            presenceStatusType = PresenceStatusType.Offline;
    }

    return <PresenceIndicator
        className={className}
        theme={theme ?? currentTheme}
        type={presenceStatusType}
    />;
}
