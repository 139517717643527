'use client';

import { ReactNode } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { GitLogEntry } from '../../src/types/gitlog';
import styles from './changelog.module.css';
import { UserProfileUser } from '../../src/models/socket/profile';
import UserProfileComponent from '../user/userprofilecomponent';

type Props = {
    entry: GitLogEntry;
    userProfile?: UserProfileUser;
}

export default function ChangeLogEntry({
    entry,
    userProfile,
}: Props): ReactNode {
    entry.date = new Date(entry.date);

    let author: ReactNode = <div className={styles.author}>{entry.author}</div>;
    if (userProfile) {
        author = <UserProfileComponent className={styles.author} user={userProfile}/>;
    }

    return <div className={styles.entry}>
        <div className={styles.infoBox}>
            <div className={styles.hash}>{entry.hash}</div>
            {author}
            <div className={styles.date}>{entry.date.toLocaleString()}</div>
        </div>
        <div className={styles.subject}>{entry.subject}</div>
        <Markdown className={styles.body} remarkPlugins={[remarkGfm]}>{entry.body}</Markdown>
    </div>
}
