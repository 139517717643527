/**
 * Just a small documentation and use to make sure
 * UGC will fit in the db columns
 */

export const MAX_STRING_LENGTH = 191;
export const MAX_TITLE_LENGTH = 40;
export const MAX_NAME_LENGTH = 25;
export const MIN_PASSWORD_LENGTH = 6;
export function truncateStringToLength(s: string, length = MAX_STRING_LENGTH, addEllipsis = false) {
    if (s.length <= length) {
        return s;
    }
    if (addEllipsis) {
        return `${s.substring(0, length - 1)}…`;
    }
    return s.substring(0, length);
}
