import { ButtonGroup, Divider, InputGroup, Label } from "@blueprintjs/core";
import { useCallback } from "react";
import SButton from "../global/sbutton";

interface Props {
    buttonText: string;
    email?: string;
    hideEmail?: boolean;
    hideName?: boolean;
    name?: string;
    isSubmitDisabled: boolean;
    password: string;
    onSubmit: () => Promise<void>;
    setEmail: (email: string) => void;
    setName?: (name: string) => void;
    setPassword: (password: string) => void;
    forgotPassword?: () => void;
}

export default function HomepageAccountForm({
    buttonText,
    email,
    hideEmail,
    hideName,
    isSubmitDisabled,
    name,
    password,
    onSubmit,
    setEmail,
    setName,
    setPassword,
    forgotPassword,
}: Props) {
    const handleKeyDown = useCallback(
        (evt) => {
            if (evt.key === 'Enter') {
                onSubmit();
            }
        },
        [onSubmit],
    );
    return (
        <div>
            {!hideEmail && <Label>
                Email:
                <InputGroup
                    type="text"
                    name="email"
                    id="email"
                    value={email}
                    autoFocus={true}
                    onValueChange={setEmail}
                    onKeyDown={handleKeyDown}
                />
            </Label>}
            <Label>
                Password:
                <InputGroup
                    type="password"
                    name="password"
                    id="password"
                    value={password}
                    autoFocus={hideEmail}
                    onValueChange={setPassword}
                    onKeyDown={handleKeyDown}
                />
            </Label>
            {!hideName && <Label>
                Display Name:
                <InputGroup
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onValueChange={setName}
                    onKeyDown={handleKeyDown}
                />
            </Label>}
            <ButtonGroup fill={true} vertical={false}>
                <SButton onClick={onSubmit} disabled={isSubmitDisabled} text={buttonText}/>
                { forgotPassword ? <Divider /> : null }
                {
                    forgotPassword
                    ? <SButton onClick={forgotPassword} disabled={isSubmitDisabled} text="Forgot Password?"/>
                    : null
                }
            </ButtonGroup>
        </div>
    );
}
