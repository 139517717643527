import { MapBorderType } from "../enum/map";
import { ApiMapBorder } from "../types/apimap";
import getItemById from "./getItemById";

class Node {
    public neighbors: Node[];
    public shortestPath: Node[];
    public visited: boolean;

    constructor(public id: number) {
        this.neighbors = [];
        this.shortestPath = [];
        this.visited = false;
    }

    toJSON() {
        return {
            id: this.id,
            neighbors: this.neighbors.map(n => n.id),
            visited: this.visited,
        };
    }
}

export default class Pathfind {
    private nodes: Node[];

    constructor(
        ids: number[],
        borders: ApiMapBorder[],
    ) {
        this.nodes = ids.map(
            id => new Node(id),
        );

        for (const border of borders) {
            const fromNode: Node = getItemById(this.nodes, border.from);
            const toNode: Node = getItemById(this.nodes, border.to);
            const type = border.type ?? MapBorderType.TWO_WAY;

            if (!fromNode || !toNode) {
                continue;
            }

            fromNode.neighbors.push(toNode);
            if (type === MapBorderType.TWO_WAY) {
                toNode.neighbors.push(fromNode);
            }
        }
    }

    private resetNodes(): void {
        for (const node of this.nodes) {
            node.shortestPath = null;
        }
    }

    public shortestPath(
        startId: number,
        maxDistance: number = Number.MAX_SAFE_INTEGER,
        terminalNodes?: number[],
    ): number[][] {
        this.resetNodes();

        const rootNode = getItemById(this.nodes, startId);
        rootNode.shortestPath = [rootNode];

        const nodesToExplore = [rootNode];

        while (nodesToExplore.length > 0) {
            const node = nodesToExplore.shift();

            for (const neighbor of node.neighbors) {
                if (neighbor.shortestPath !== null) {
                    continue;
                }

                if (node.shortestPath.length > maxDistance) {
                    continue;
                }

                neighbor.shortestPath = node.shortestPath.concat(neighbor);

                if (
                    !terminalNodes
                    || !terminalNodes.includes(neighbor.id)
                ) {
                    nodesToExplore.push(neighbor);
                }
            }
        }

        return this.nodes
            .filter((node) => node.id !== startId)
            .filter((node) => node.shortestPath != null)
            .map(
                (node) => node.shortestPath.map((pathNode) => pathNode.id)
            );
    }

    getNeighborsOf(id: number): Node[] {
        const targetNode = this.nodes.find(
            node => node.id === id
        );
        if (!targetNode) {
            return [];
        }

        return this.nodes.filter(
            node => node.neighbors.includes(targetNode)
        );
    }

    removeNode(id: number) {
        const nodeIndex = this.nodes.findIndex(
            (n) => n.id === id
        );
        if (nodeIndex < 0) {
            return;
        }
        const node = this.nodes[nodeIndex];

        for (const aNode of this.getNeighborsOf(id)) {
            const index = aNode.neighbors.indexOf(node);
            aNode.neighbors.splice(index, 1);
        }

        this.nodes.splice(nodeIndex, 1);
    }

    /**
     * Check to make sure the graph does not get bisected when removing
     * a country
     */
    async isGraphConnectedAfterRemoving(
        idToRemove: number,
    ): Promise<boolean> {
        const nodeToRemoveIndex = this.nodes.findIndex(
            node => node.id === idToRemove
        );
        if (nodeToRemoveIndex < 0) {
            // already removed
            return true;
        }

        const neighbors = this.getNeighborsOf(idToRemove);
        for (const neighbor of neighbors) {
            neighbor.visited = false;
        }

        const nodeToRemove = this.nodes[nodeToRemoveIndex];
        nodeToRemove.visited = true;

        const visitQueue = [neighbors[0]];
        while (visitQueue.length > 0) {
            const node = visitQueue.shift();
            if (node.visited) {
                continue;
            }
            for (const neighbor of node.neighbors) {
                if (!neighbor.visited) {
                    visitQueue.push(neighbor);
                }
            }
            node.visited = true;
        }

        const isConnected = this.nodes.every(
            node => node.visited
        );

        for (const neighbor of neighbors) {
            neighbor.visited = true;
        }

        return isConnected;
    }
}
