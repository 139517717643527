enum GameState {
    Created = 0,
    PlaceUnits,
    Attack,
    Transfer,
    Fortify,
    Complete, // 5
    History,
    PlaceCapital,
}

export default GameState;
