import { ApiSeat } from "../../src/types/apigame";
import UserPlayerAvatar, { UserPlayerAvatarSize } from "./playeravatar";

interface UserSeatArgs {
    seatObject: ApiSeat;
    size?: UserPlayerAvatarSize;
    userId: number;
    disableAcceptedDimming?: boolean;
}

export default function UserSeat(
    {
        seatObject,
        size,
        userId,
        disableAcceptedDimming,
    }: UserSeatArgs,
) {
    const name = seatObject.userId === userId ? 'you' : seatObject.name;

    return <>
        <UserPlayerAvatar
            seatObject={seatObject}
            showProfileOnClick={false}
            size={size}
            disableAcceptedDimming={!!disableAcceptedDimming}
        />
        <span>
            &nbsp;
            {name}
        </span>
    </>;
}
