import { MAX_NAME_LENGTH, MAX_STRING_LENGTH, MIN_PASSWORD_LENGTH } from "../../util/restrictions";

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Validates user registration parameters.
 * 
 * Returns null if ok, returns string with message if error
 * @param email 
 * @param password 
 * @param name 
 */
export function validateUserRegistrationParams(
    email: string,
    password: string,
    name: string,
): string {
    if (!email) {
        return 'Please enter an e-mail address.';
    } else if (!EMAIL_REGEX.exec(email)) {
        return 'Please enter a valid e-mail address.';
    } else if (email.length > MAX_STRING_LENGTH) {
        `Email address must be under ${MAX_STRING_LENGTH} characters`;
    } else if (!password) {
        return 'Please enter a password.';
    } else if (password.length < MIN_PASSWORD_LENGTH) {
        return `Password must be at least ${MIN_PASSWORD_LENGTH} characters long.`;
    } else if (password.length > MAX_STRING_LENGTH) {
        `Password be under ${MAX_STRING_LENGTH} characters`;
    } else if (!name) {
        return 'Please enter a display name.';
    } else if (name.length > MAX_NAME_LENGTH) {
        return `Name must be under ${MAX_NAME_LENGTH} characters`;
    }

    return null;
}
