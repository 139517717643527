import { Button, Spinner, SpinnerSize } from "@blueprintjs/core";
import { useSession } from "next-auth/react"
import NavbarSignInButtonContainer from "./signinbuttoncontainer";
import NavbarLoggedInContainer from "./loggedin";

export default function NavbarAccountButtonContainer() {
    const { status } = useSession();

    let content = null;

    if (status === 'loading') {
        content = (
            <Button>
                <Spinner size={SpinnerSize.SMALL} />
            </Button>
        );
    } else if (status === 'unauthenticated') {
        content = <NavbarSignInButtonContainer />
    } else if (status === 'authenticated') {
        content = <NavbarLoggedInContainer />
    }

    return content;
}
