import { Card, Elevation } from "@blueprintjs/core";
import { ApiGame, ApiSeat } from "../../../src/types/apigame";
import styles from './gameslistgame.module.css';
import GamesListMap from "./gameslistmap";
import GamesListAvatars from "./gameslistavatars";
import GameGamesListTurn from "./gameslistturn";
import { ThumbnailType } from "../../../src/models/map/thumbnailtypes";
import { UserPlayerAvatarSize } from "../../user/playeravatar";
import GameState from "../../../src/enum/gamestate";
import GameGamesListJewel from "./gameslistjewel";
import GamesListGameModeIcon from "./gameslistgamemodeicon";
import Link from "next/link";

type Props = {
    game: ApiGame;
    userId: number;
};

export default function GamesListGame(
    {
        game,
        userId,
    }: Props,
): JSX.Element {
    const activeSeats: ApiSeat[] = game.activeSeats;
    const isInvited = game.invites?.find(
        (invite) => invite.user?.id === userId,
    );

    const classes = [styles.card];

    const isMyTurn = userId != null
        && (isInvited || activeSeats.some((seatObject) => seatObject.userId === userId));

    if (game.state === GameState.Complete && isMyTurn) {
        classes.push(styles.didWin);
    } else if (isMyTurn) {
        classes.push(styles.isMyTurn);
    }

    return <Link href={`/game/play?id=${game.id}`} className={styles.link}>
        <Card
            className={classes.join(' ')}
            interactive={true}
            elevation={Elevation.FOUR}
            style={{ backgroundImage: `url("/api/map/getthumbnail?mapId=${game.mapId}&type=${ThumbnailType.Card}")` }}
        >
            <div className={styles.container}>
                <div>
                    <GamesListMap game={game} />
                </div>
                <div className={styles.rightCell}>
                    <div className={styles.name}>
                        <GameGamesListJewel game={game} userId={userId} />
                        <GamesListGameModeIcon game={game} />
                        <b>{game.name}</b>&nbsp;
                        ({game.map.name})
                    </div>
                    <GamesListAvatars game={game} size={UserPlayerAvatarSize.Medium} />
                    <GameGamesListTurn game={game} />
                </div>
            </div>
        </Card>
    </Link>;
}
