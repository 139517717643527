import { usePathname, useSearchParams } from "next/navigation";
import { useCallback, useEffect } from "react";

export default function BlueprintPopupHack(): JSX.Element {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const removeOverlay = useCallback(
        () => {
            const body = document.body;

            if (!body.classList.contains('bp5-overlay-open')) {
                return;
            }

            const portals = document.getElementsByClassName('bp5-portal');
            if (portals.length > 0) {
                return;
            }

            body.classList.remove('bp5-overlay-open');
        },
        [],
    );

    // fix issue when navigating away from a page when a popup is open
    useEffect(
        () => {
            removeOverlay();
        },
        [pathname, searchParams, removeOverlay],
    );

    return <div id="blueprintPopupFixer" style={{ display: 'none' }} />;
}
