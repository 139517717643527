import { HTMLSelect } from "@blueprintjs/core";
import { RuleEnum } from "../../../src/types/rule";

type Props = {
    isDisabled?: boolean;
    isEditable: boolean;
    onChange: (any) => void;
    rule: RuleEnum;
    value: any;
};

export default function GameRuleEnum(enumType: any) {
    return function GameRuleEnumComponent({
        isDisabled,
        isEditable,
        onChange,
        rule,
        value,
    }: Props) {
        if (!isEditable) {
            return <div>{enumType[value]}</div>
        }

        return <HTMLSelect
            disabled={isDisabled}
            id={rule.key}
            onChange={(e) => onChange(Number(e.currentTarget.value))}
            options={
                Object
                    .keys(enumType)
                    .filter(key => isNaN(Number(key)))
                    .map(
                        key => (
                            {
                                label: key,
                                value: enumType[key],
                            }
                        )
                    )
            }
            value={value}
        />
    }
}
