export default function getItemById<T, V = number>(
    collection: T[],
    id: V,
    idField: string = 'id',
): T {
    if (!collection) {
        return null;
    }
    for (let i = 0; i < collection.length; ++i) {
        const item = collection[i];
        if (item[idField] === id) {
            return item;
        }
    }

    return null;
}
