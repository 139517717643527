import { ControlGroup, Slider } from "@blueprintjs/core";
import { useCallback } from "react";
import { IconNames } from "@blueprintjs/icons";
import styles from './settings.module.css';
import { useLocalStorage } from "usehooks-ts";
import SButton from "../../global/sbutton";
import { LocalStorageKeys } from "../../../src/enum/localstorage";

const UNIT_TEXT_SIZE_MIN = 0.5;
const UNIT_TEXT_SIZE_MAX = 2.5;
const UNIT_TEXT_SIZE_STEP_SIZE = 0.25; 

export default function SettingsUnitTextSize(): JSX.Element {
    const [value, setValue] = useLocalStorage<number>(LocalStorageKeys.UnitTextSize, 1);

    const onChange = useCallback(
        (newValue: number) => {
            setValue(newValue);
        },
        [setValue],
    );

    const labelRenderer = useCallback(
        (valueToRender: number): string => `${valueToRender}x`,
        [],
    );

    const decreaseValue = useCallback(
        () => {
            if (value > UNIT_TEXT_SIZE_MIN) {
                setValue(value - UNIT_TEXT_SIZE_STEP_SIZE);
            }
        },
        [setValue, value],
    );

    const increaseValue = useCallback(
        () => {
            if (value < UNIT_TEXT_SIZE_MAX) {
                setValue(value + UNIT_TEXT_SIZE_STEP_SIZE);
            }
        },
        [setValue, value],
    );

    return <div>
        <div><b>Unit Text Size:</b></div>
        <div>
            <ControlGroup>
                <SButton
                    disabled={value <= UNIT_TEXT_SIZE_MIN}
                    icon={IconNames.MINUS}
                    onClick={decreaseValue}
                />
                <Slider
                    labelRenderer={labelRenderer}
                    min={0.5}
                    max={2.5}
                    stepSize={0.25}
                    value={value}
                    onChange={onChange}
                    className={styles.settingsVolumeSlider}
                />
                <SButton
                    disabled={value >= UNIT_TEXT_SIZE_MAX}
                    icon={IconNames.PLUS}
                    onClick={increaseValue}
                    className={styles.settingsVolumeButtonFull}
                />
            </ControlGroup>
        </div>
    </div>;
}
