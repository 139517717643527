import React from 'react';
import { SessionProvider } from "next-auth/react"
import dynamic from 'next/dynamic';

import '../styles/global.css';
import BuildInfo from '../components/global/build_info';
import { SocketProvider } from '../components/global/socket';
import Notify from '../components/global/notify';
import { FocusStyleManager } from '@blueprintjs/core';
import { PageFocusProvider } from '../components/global/pagefocus';
import { SoundManagerProvider } from '../components/global/sound/soundmanager';
import DialogManager from '../components/global/dialog';
import { ActiveGamesProvider } from '../components/global/activegamesprovider';
import NavigationRegular from '../components/navbar/navigationregular';
import SpqrHead from '../components/global/spqrhead';
import PresenceProvider from '../components/global/presence';
import { ServerFlagsProvider } from '../components/global/serverflags';
import BlueprintPopupHack from '../components/global/blueprintpopuphack';

const ThemeProvider = dynamic(
    () => import('../components/global/theme'),
    { ssr: false },
);

export default function App(
    {
        Component,
        pageProps: { session, ...pageProps }
    },
) {
    FocusStyleManager.onlyShowFocusOnTabs();

    return (
        <SessionProvider session={session}>
            <ThemeProvider>
                <SocketProvider>
                    <ServerFlagsProvider>
                        <ActiveGamesProvider>
                            <PageFocusProvider>
                                <SoundManagerProvider>
                                    <PresenceProvider>
                                        <DialogManager>
                                            <Notify />
                                            <SpqrHead />
                                            <NavigationRegular />
                                            <div id="main">
                                                <Component {...pageProps} />
                                            </div>
                                            <BuildInfo />
                                            <BlueprintPopupHack />
                                        </DialogManager>
                                    </PresenceProvider>
                                </SoundManagerProvider>
                            </PageFocusProvider>
                        </ActiveGamesProvider>
                    </ServerFlagsProvider>
                </SocketProvider>
            </ThemeProvider>
        </SessionProvider>
    );
}
