import { useMemo } from "react";
import GameState from "../../../src/enum/gamestate";
import { ApiGame, ApiSeat } from "../../../src/types/apigame";
import UserPlayerAvatar, { UserPlayerAvatarSize } from "../../user/playeravatar";
import styles from './gameslistgame.module.css';
import { GamePrivacy } from "../../../src/enum/game";

type Props = {
    game: ApiGame;
    size?: UserPlayerAvatarSize;
}

export default function GamesListAvatars(
    {
        game,
        size,
    }: Props,
): JSX.Element {
    const hasGameStarted = game.state !== GameState.Created;

    const extraSeats = useMemo(
        () => {
            if (hasGameStarted || game.privacy !== GamePrivacy.AnyoneCanJoin) {
                return null;
            }

            const numCurrentSeats = game.seats.length;
            const maxSeats = game.maxSeats;

            if (numCurrentSeats >= maxSeats) {
                return null;
            }

            const blankSeats = [];

            for (let i = numCurrentSeats; i < maxSeats; ++i) {
                blankSeats.push(<UserPlayerAvatar
                    game={game}
                    hasGameStarted={hasGameStarted}
                    key={i}
                    showProfileOnClick={false}
                    size={size}
                />);
            }

            return blankSeats;
        },
        [game, hasGameStarted, size],
    );

    return (
        <div className={styles.avatars}>
            {
                game.seats
                    .sort((a, b) => a.seatNumber - b.seatNumber)
                    .map(
                        (seat, index) => <UserPlayerAvatar
                            game={game}
                            hasGameStarted={hasGameStarted}
                            key={index}
                            seatObject={seat}
                            showProfileOnClick={false}
                            size={size}
                        />
                    )
            }
            {extraSeats}
        </div>
    )
}
