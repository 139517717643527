const AvatarHeads = [
    "100_HeadFront.png",
    "101_HeadFront.png",
    "102_HeadFront.png",
    "103_HeadFront.png",
    "104_HeadFront.png",
    "105_HeadFront.png",
    "106_HeadFront.png",
    "107_HeadFront.png",
    "108_HeadFront.png",
    "109_HeadFront.png",
    "10_HeadFront.png",
    "110_HeadFront.png",
    "111_HeadFront.png",
    "112_HeadFront.png",
    "113_HeadFront.png",
    "114_HeadFront.png",
    "115_HeadFront.png",
    "116_HeadFront.png",
    "117_HeadFront.png",
    "118_HeadFront.png",
    "119_HeadFront.png",
    "11_HeadFront.png",
    "120_HeadFront.png",
    "121_HeadFront.png",
    "122_HeadFront.png",
    "123_HeadFront.png",
    "124_HeadFront.png",
    "125_HeadFront.png",
    "126_HeadFront.png",
    "127_HeadFront.png",
    "128_HeadFront.png",
    "129_HeadFront.png",
    "12_HeadFront.png",
    "130_HeadFront.png",
    "131_HeadFront.png",
    "132_HeadFront.png",
    "133_HeadFront.png",
    "134_HeadFront.png",
    "135_HeadFront.png",
    "136_HeadFront.png",
    "137_HeadFront.png",
    "138_HeadFront.png",
    "139_HeadFront.png",
    "13_HeadFront.png",
    "140_HeadFront.png",
    "141_HeadFront.png",
    "142_HeadFront.png",
    "143_HeadFront.png",
    "144_HeadFront.png",
    "145_HeadFront.png",
    "146_HeadFront.png",
    "147_HeadFront.png",
    "148_HeadFront.png",
    "149_HeadFront.png",
    "14_HeadFront.png",
    "150_HeadFront.png",
    "151_HeadFront.png",
    "152_HeadFront.png",
    "153_HeadFront.png",
    "154_HeadFront.png",
    "155_HeadFront.png",
    "156_HeadFront.png",
    "157_HeadFront.png",
    "158_HeadFront.png",
    "159_HeadFront.png",
    "15_HeadFront.png",
    "160_HeadFront.png",
    "161_HeadFront.png",
    "162_HeadFront.png",
    "163_HeadFront.png",
    "164_HeadFront.png",
    "165_HeadFront.png",
    "166_HeadFront.png",
    "167_HeadFront.png",
    "168_HeadFront.png",
    "169_HeadFront.png",
    "16_HeadFront.png",
    "170_HeadFront.png",
    "171_HeadFront.png",
    "172_HeadFront.png",
    "173_HeadFront.png",
    "174_HeadFront.png",
    "175_HeadFront.png",
    "176_HeadFront.png",
    "177_HeadFront.png",
    "178_HeadFront.png",
    "179_HeadFront.png",
    "17_HeadFront.png",
    "180_HeadFront.png",
    "181_HeadFront.png",
    "182_HeadFront.png",
    "183_HeadFront.png",
    "184_HeadFront.png",
    "185_HeadFront.png",
    "186_HeadFront.png",
    "187_HeadFront.png",
    "188_HeadFront.png",
    "189_HeadFront.png",
    "18_HeadFront.png",
    "190_HeadFront.png",
    "191_HeadFront.png",
    "192_HeadFront.png",
    "193_HeadFront.png",
    "194_HeadFront.png",
    "195_HeadFront.png",
    "196_HeadFront.png",
    "197_HeadFront.png",
    "198_HeadFront.png",
    "199_HeadFront.png",
    "19_HeadFront.png",
    "1_HeadFront.png",
    "2001_HeadFront.png",
    "2002_HeadFront.png",
    "2003_HeadFront.png",
    "2004_HeadFront.png",
    "200_HeadFront.png",
    "2011_HeadFront.png",
    "2012_HeadFront.png",
    "2013_HeadFront.png",
    "2014_HeadFront.png",
    "201_HeadFront.png",
    "2021_HeadFront.png",
    "2022_HeadFront.png",
    "2023_HeadFront.png",
    "2024_HeadFront.png",
    "202_HeadFront.png",
    "2031_HeadFront.png",
    "2032_HeadFront.png",
    "2033_HeadFront.png",
    "2034_HeadFront.png",
    "203_HeadFront.png",
    "2041_HeadFront.png",
    "2042_HeadFront.png",
    "2043_HeadFront.png",
    "2044_HeadFront.png",
    "204_HeadFront.png",
    "2051_HeadFront.png",
    "2052_HeadFront.png",
    "2053_HeadFront.png",
    "2054_HeadFront.png",
    "205_HeadFront.png",
    "2061_HeadFront.png",
    "2062_HeadFront.png",
    "2063_HeadFront.png",
    "2064_HeadFront.png",
    "206_HeadFront.png",
    "2071_HeadFront.png",
    "2072_HeadFront.png",
    "2073_HeadFront.png",
    "2074_HeadFront.png",
    "207_HeadFront.png",
    "208_HeadFront.png",
    "209_HeadFront.png",
    "20_HeadFront.png",
    "210_HeadFront.png",
    "211_HeadFront.png",
    "212_HeadFront.png",
    "213_HeadFront.png",
    "214_HeadFront.png",
    "215_HeadFront.png",
    "216_HeadFront.png",
    "217_HeadFront.png",
    "218_HeadFront.png",
    "219_HeadFront.png",
    "21_HeadFront.png",
    "220_HeadFront.png",
    "221_HeadFront.png",
    "222_HeadFront.png",
    "223_HeadFront.png",
    "224_HeadFront.png",
    "225_HeadFront.png",
    "226_HeadFront.png",
    "227_HeadFront.png",
    "228_HeadFront.png",
    "229_HeadFront.png",
    "22_HeadFront.png",
    "230_HeadFront.png",
    "231_HeadFront.png",
    "232_HeadFront.png",
    "233_HeadFront.png",
    "234_HeadFront.png",
    "235_HeadFront.png",
    "236_HeadFront.png",
    "237_HeadFront.png",
    "238_HeadFront.png",
    "239_HeadFront.png",
    "23_HeadFront.png",
    "240_HeadFront.png",
    "241_HeadFront.png",
    "242_HeadFront.png",
    "243_HeadFront.png",
    "244_HeadFront.png",
    "245_HeadFront.png",
    "246_HeadFront.png",
    "247_HeadFront.png",
    "248_HeadFront.png",
    "249_HeadFront.png",
    "24_HeadFront.png",
    "250_HeadFront.png",
    "251_HeadFront.png",
    "252_HeadFront.png",
    "253_HeadFront.png",
    "254_HeadFront.png",
    "255_HeadFront.png",
    "256_HeadFront.png",
    "257_HeadFront.png",
    "258_HeadFront.png",
    "259_HeadFront.png",
    "260_HeadFront.png",
    "261_HeadFront.png",
    "262_HeadFront.png",
    "263_HeadFront.png",
    "264_HeadFront.png",
    "265_HeadFront.png",
    "266_HeadFront.png",
    "267_HeadFront.png",
    "268_HeadFront.png",
    "269_HeadFront.png",
    "26_HeadFront.png",
    "270_HeadFront.png",
    "271_HeadFront.png",
    "272_HeadFront.png",
    "273_HeadFront.png",
    "274_HeadFront.png",
    "275_HeadFront.png",
    "276_HeadFront.png",
    "277_HeadFront.png",
    "278_HeadFront.png",
    "279_HeadFront.png",
    "27_HeadFront.png",
    "280_HeadFront.png",
    "281_HeadFront.png",
    "282_HeadFront.png",
    "283_HeadFront.png",
    "284_HeadFront.png",
    "285_HeadFront.png",
    "286_HeadFront.png",
    "287_HeadFront.png",
    "288_HeadFront.png",
    "289_HeadFront.png",
    "28_HeadFront.png",
    "290_HeadFront.png",
    "291_HeadFront.png",
    "292_HeadFront.png",
    "293_HeadFront.png",
    "294_HeadFront.png",
    "295_HeadFront.png",
    "296_HeadFront.png",
    "297_HeadFront.png",
    "298_HeadFront.png",
    "299_HeadFront.png",
    "29_HeadFront.png",
    "2_HeadFront.png",
    "300_HeadFront.png",
    "301_HeadFront.png",
    "302_HeadFront.png",
    "303_HeadFront.png",
    "304_HeadFront.png",
    "305_HeadFront.png",
    "306_HeadFront.png",
    "307_HeadFront.png",
    "308_HeadFront.png",
    "309_HeadFront.png",
    "30_HeadFront.png",
    "310_HeadFront.png",
    "311_HeadFront.png",
    "312_HeadFront.png",
    "313_HeadFront.png",
    "314_HeadFront.png",
    "315_HeadFront.png",
    "316_HeadFront.png",
    "317_HeadFront.png",
    "318_HeadFront.png",
    "319_HeadFront.png",
    "31_HeadFront.png",
    "320_HeadFront.png",
    "321_HeadFront.png",
    "322_HeadFront.png",
    "323_HeadFront.png",
    "324_HeadFront.png",
    "325_HeadFront.png",
    "326_HeadFront.png",
    "327_HeadFront.png",
    "328_HeadFront.png",
    "329_HeadFront.png",
    "32_HeadFront.png",
    "330_HeadFront.png",
    "331_HeadFront.png",
    "332_HeadFront.png",
    "333_HeadFront.png",
    "334_HeadFront.png",
    "335_HeadFront.png",
    "336_HeadFront.png",
    "337_HeadFront.png",
    "338_HeadFront.png",
    "339_HeadFront.png",
    "33_HeadFront.png",
    "340_HeadFront.png",
    "341_HeadFront.png",
    "342_HeadFront.png",
    "343_HeadFront.png",
    "344_HeadFront.png",
    "345_HeadFront.png",
    "346_HeadFront.png",
    "347_HeadFront.png",
    "348_HeadFront.png",
    "349_HeadFront.png",
    "34_HeadFront.png",
    "350_HeadFront.png",
    "351_HeadFront.png",
    "352_HeadFront.png",
    "353_HeadFront.png",
    "354_HeadFront.png",
    "355_HeadFront.png",
    "356_HeadFront.png",
    "357_HeadFront.png",
    "358_HeadFront.png",
    "359_HeadFront.png",
    "35_arrow_HeadFront.png",
    "35_HeadFront.png",
    "35_heart_HeadFront.png",
    "360_HeadFront.png",
    "361_HeadFront.png",
    "362_HeadFront.png",
    "363_HeadFront.png",
    "364_HeadFront.png",
    "365_HeadFront.png",
    "366_HeadFront.png",
    "367_HeadFront.png",
    "368_HeadFront.png",
    "369_HeadFront.png",
    "36_HeadFront.png",
    "370_HeadFront.png",
    "371_HeadFront.png",
    "372_HeadFront.png",
    "373_HeadFront.png",
    "374_HeadFront.png",
    "375_HeadFront.png",
    "376_HeadFront.png",
    "377_HeadFront.png",
    "378_HeadFront.png",
    "379_HeadFront.png",
    "37_HeadFront.png",
    "380_HeadFront.png",
    "381_HeadFront.png",
    "382_HeadFront.png",
    "383_HeadFront.png",
    "384_HeadFront.png",
    "385_HeadFront.png",
    "386_HeadFront.png",
    "387_HeadFront.png",
    "388_HeadFront.png",
    "389_HeadFront.png",
    "38_HeadFront.png",
    "390_HeadFront.png",
    "391_HeadFront.png",
    "392_HeadFront.png",
    "393_HeadFront.png",
    "394_HeadFront.png",
    "395_HeadFront.png",
    "396_HeadFront.png",
    "397_HeadFront.png",
    "398_HeadFront.png",
    "399_HeadFront.png",
    "39_HeadFront.png",
    "3_HeadFront.png",
    "400_HeadFront.png",
    "401_HeadFront.png",
    "402_HeadFront.png",
    "403_HeadFront.png",
    "404_HeadFront.png",
    "405_HeadFront.png",
    "406_HeadFront.png",
    "407_HeadFront.png",
    "408_HeadFront.png",
    "409_HeadFront.png",
    "40_HeadFront.png",
    "410_HeadFront.png",
    "411_HeadFront.png",
    "412_HeadFront.png",
    "413_HeadFront.png",
    "414_HeadFront.png",
    "415_HeadFront.png",
    "416_HeadFront.png",
    "417_HeadFront.png",
    "418_HeadFront.png",
    "419_HeadFront.png",
    "41_HeadFront.png",
    "420_HeadFront.png",
    "421_HeadFront.png",
    "422_HeadFront.png",
    "423_HeadFront.png",
    "424_HeadFront.png",
    "425_HeadFront.png",
    "426_HeadFront.png",
    "427_HeadFront.png",
    "428_HeadFront.png",
    "429_HeadFront.png",
    "42_HeadFront.png",
    "430_HeadFront.png",
    "431_HeadFront.png",
    "432_HeadFront.png",
    "43_HeadFront.png",
    "44_HeadFront.png",
    "45_HeadFront.png",
    "46_HeadFront.png",
    "47_HeadFront.png",
    "48_HeadFront.png",
    "49_HeadFront.png",
    "4dff8d24a3f75104de04797e_HeadFront.png",
    "4e02e0094fe7d061360399ea_HeadFront.png",
    "4e04f4624fe7d00b64041402_HeadFront.png",
    "4e08e2caa3f7517cfd0521e2_HeadFront.png",
    "4e08e69da3f7517d0704e652_HeadFront.png",
    "4e0aa9494fe7d076af023110_HeadFront.png",
    "4e0b64524fe7d076af066895_HeadFront.png",
    "4e0b8e98a3f75146740942d2_HeadFront.png",
    "4e0b8f6ea3f7514674094ff6_HeadFront.png",
    "4e0cba24a3f751466f12e22a_HeadFront.png",
    "4e0d9c9c4fe7d076b71e0867_HeadFront.png",
    "4e14c6c2a3f75102cd02ecac_HeadFront.png",
    "4e15181ca3f75109d3038f85_HeadFront.png",
    "4e152419a3f75109da038ecb_HeadFront.png",
    "4e1ec193a3f75107c8054a96_HeadFront.png",
    "4e1f58c0a3f75107c50a3967_HeadFront.png",
    "4e6576eca3f7513f4b03ab99_HeadFront.png",
    "4e976344a3f7515e5c03f37c_HeadFront.png",
    "4edc4da4a3f75122f8001056_HeadFront.png",
    "4f052f94590ca25fa2002ed1_HeadFront.png",
    "4f3d760b590ca2028b003a62_HeadFront.png",
    "4f51a141a3f7515819001a05_HeadFront.png",
    "4f66c106a3f751581a0255e4_HeadFront.png",
    "4fa42c5baaa5cd11070001b3_HeadFront.png",
    "4fbe885aaaa5cd0470000003_HeadFront.png",
    "4fe0b5d6eb35c12d810000f1_HeadFront.png",
    "4_HeadFront.png",
    "500ecbbeaaa5cd670f000267_HeadFront.png",
    "501b59b8eb35c11e67000049_headfront.png",
    "5059248aaaa5cd4621000289_HeadFront.png",
    "50ad884beb35c179dbc27883_HeadFront.png",
    "50db354faaa5cd0470d03451_HeadFront.png",
    "50eb7324eb35c1657fc7d3d8_HeadFront.png",
    "50eb7343eb35c1657fc7d3dc_HeadFront.png",
    "50eb7360eb35c1657fc7d3e0_HeadFront.png",
    "50ecab81eb35c1187bc1983d_HeadFront.png",
    "50ecbd9deb35c1187bc198aa_HeadFront.png",
    "50f2d7a8eb35c1017548b342_HeadFront.png",
    "50f49988aaa5cd62923d79ec_HeadFront.png",
    "50f67ca9aaa5cd1928bacc8c_HeadFront.png",
    "50f6e447eb35c13929bdd375_HeadFront.png",
    "50f76a98eb35c14849903390_HeadFront.png",
    "50f8ccd4aaa5cd4d771e5a50_HeadFront.png",
    "50f97e7faaa5cd4d8637a9f3_HeadFront.png",
    "50ff0f1aeb35c12c52f54e43_HeadFront.png",
    "50ff22b4aaa5cd6af82ff5a7_HeadFront.png",
    "50ff44deeb35c12c5eb60294_HeadFront.png",
    "50ff55d5aaa5cd6b11a876f8_HeadFront.png",
    "50ff76a4aaa5cd6af8300606_HeadFront.png",
    "50_HeadFront.png",
    "51030f27eb35c175daf5597c_HeadFront.png",
    "5107f9caaaa5cd739f6ed57e_HeadFront.png",
    "510c30d0eb35c135f90f4828_HeadFront.png",
    "510f13fbeb35c135f0d4d6d3_HeadFront.png",
    "51102ab8aaa5cd76266e4701_HeadFront.png",
    "511eb351eb35c16c42849066_HeadFront.png",
    "51229282eb35c14a0510fe22_HeadFront.png",
    "512293e0eb35c14a0510fe3e_HeadFront.png",
    "5123e86beb35c149eb8329c9_HeadFront.png",
    "512655d4aaa5cd7abe349579_HeadFront.png",
    "512bac94aaa5cd3d94a05578_HeadFront.png",
    "512bb376aaa5cd3d94a055ff_HeadFront.png",
    "512be939aaa5cd3d94a05960_HeadFront.png",
    "512bead9aaa5cd3d94a0599c_HeadFront.png",
    "512e7817eb35c129d54ce342_HeadFront.png",
    "5137dfc7eb35c150d8590fba_HeadFront.png",
    "5137dff1eb35c150d8590fc0_HeadFront.png",
    "5137e025eb35c150d8590fc5_HeadFront.png",
    "5137e066eb35c150d8590fca_HeadFront.png",
    "513931a7eb35c11b82d4b907_HeadFront.png",
    "5139322ceb35c11b82d4b90f_HeadFront.png",
    "51393258eb35c11b82d4b913_HeadFront.png",
    "51393279eb35c11b82d4b917_HeadFront.png",
    "513a4a9beb35c1193e5f6908_HeadFront.png",
    "513ab018aaa5cd76ab64e89e_HeadFront.png",
    "51435228aaa5cd280ad7c21f_HeadFront.png",
    "514b4c7feb35c1020fbaffcf_HeadFront.png",
    "514b9377eb35c10221ebacc3_HeadFront.png",
    "514b938feb35c10221ebacc8_HeadFront.png",
    "514f69ceeb35c137e111670d_HeadFront.png",
    "51508964aaa5cd547a09a052_HeadFront.png",
    "515e0cdaeb35c13fd2a1e306_HeadFront.png",
    "51631355aaa5cd643a29b62c_HeadFront.png",
    "5163304faaa5cd347385b084_HeadFront.png",
    "516591d2eb35c16eb9c876f6_HeadFront.png",
    "516596e6eb35c16eb9c8775e_HeadFront.png",
    "516596f9eb35c16eb9c87762_HeadFront.png",
    "51659712eb35c16eb9c87766_HeadFront.png",
    "516db1a8eb35c1117115855e_HeadFront.png",
    "516db9b1eb35c11171158582_HeadFront.png",
    "516dbacdeb35c1117115858c_HeadFront.png",
    "516dbbd2eb35c11171158593_HeadFront.png",
    "516dbd0deb35c1117115859c_HeadFront.png",
    "516f26f5aaa5cd2efe4b2c6d_HeadFront.png",
    "51795024eb35c1314f2ece1f_HeadFront.png",
    "51795d47eb35c1314f2ece5b_HeadFront.png",
    "51895f2beb35c106d7a5d85e_HeadFront.png",
    "518c0ca4aaa5cd496f56b9cf_HeadFront.png",
    "51912b94eb35c135a74892a4_HeadFront.png",
    "51912bc1eb35c135a74892a7_HeadFront.png",
    "5191401beb35c14c1750bd63_HeadFront.png",
    "51914094eb35c14c1750bd66_HeadFront.png",
    "519140d9eb35c14c1750bd69_HeadFront.png",
    "51914124eb35c14c1750bd6c_HeadFront.png",
    "51924778eb35c10acdbc7948_HeadFront.png",
    "5192a11eeb35c12f32192e1c_HeadFront.png",
    "5196747eeb35c12d9705e51e_HeadFront.png",
    "51967506eb35c12d9705e524_HeadFront.png",
    "519a4666eb35c1500ca89363_HeadFront.png",
    "519a623aeb35c1500ca893b7_HeadFront.png",
    "519a6274eb35c1500ca893b9_HeadFront.png",
    "519a62a6eb35c1500ca893bc_HeadFront.png",
    "519ad256eb35c1500ca89516_HeadFront.png",
    "519afe92eb35c1500ca89590_HeadFront.png",
    "519c1f03eb35c10822c48a1a_HeadFront.png",
    "519d798aeb35c13e6b291ce2_HeadFront.png",
    "519d79cbeb35c13e6b291ce6_HeadFront.png",
    "519eb003eb35c10f2d31d69f_HeadFront.png",
    "519f8c2caaa5cd1d0edb9c53_HeadFront.png",
    "51a523c4eb35c1599c6342b4_HeadFront.png",
    "51a629aeeb35c141bfbfea89_HeadFront.png",
    "51a62b9beb35c141bfbfea92_HeadFront.png",
    "51a62cadeb35c141bfbfea94_HeadFront.png",
    "51a62d71eb35c141bfbfea97_HeadFront.png",
    "51a67eb2eb35c115809b2d65_HeadFront.png",
    "51a6a8c1eb35c11586b03db2_HeadFront.png",
    "51a6aa24eb35c11586b03dc2_HeadFront.png",
    "51a6abd7eb35c11586b03dce_HeadFront.png",
    "51a6ae81eb35c11586b03dd5_HeadFront.png",
    "51acc7a5eb35c1121b439fb5_HeadFront.png",
    "51aff629eb35c13c40f1883c_HeadFront.png",
    "51b04028aaa5cd56f71a99cb_HeadFront.png",
    "51b22e8ceb35c1253bf482c2_HeadFront.png",
    "51b22ec3eb35c1253bf482c5_HeadFront.png",
    "51b69680eb35c1107fcee769_HeadFront.png",
    "51b764f7eb35c14540551c70_HeadFront.png",
    "51b94665eb35c17137d2f37e_HeadFront.png",
    "51b947bfeb35c17137d2f387_HeadFront.png",
    "51b9facbeb35c141870e0322_HeadFront.png",
    "51bf218beb35c11bb5cc37e3_HeadFront.png",
    "51bff35feb35c16f0c886c43_HeadFront.png",
    "51c25862eb35c13c4280db15_HeadFront.png",
    "51c38947eb35c1490dfcac12_HeadFront.png",
    "51c4a44feb35c1432fda03fa_HeadFront.png",
    "51c862f2eb35c143262e1e97_HeadFront.png",
    "51c8630aeb35c143262e1ea0_HeadFront.png",
    "51c86360eb35c143262e1ea8_HeadFront.png",
    "51ce46efeb35c124a72831c8_HeadFront.png",
    "51d37df4eb35c124a72843df_HeadFront.png",
    "51d37e1beb35c124a72843e3_HeadFront.png",
    "51d37e32eb35c124a72843e8_HeadFront.png",
    "51db1221aaa5cd5ddb8224f4_HeadFront.png",
    "51db832feb35c136939e6749_HeadFront.png",
    "51db8361eb35c136939e674e_HeadFront.png",
    "51db837aeb35c136939e6752_HeadFront.png",
    "51e058d3eb35c114f540f7a3_HeadFront.png",
    "51e4207aeb35c14e22555e0e_HeadFront.png",
    "51e431d0eb35c14e22555e5e_HeadFront.png",
    "51e6da3ceb35c145377e4152_HeadFront.png",
    "51e96ce4eb35c1324bbeccd2_HeadFront.png",
    "51ed66daeb35c151cc9e64d3_HeadFront.png",
    "51ed6d31eb35c107fa1f4e06_HeadFront.png",
    "51ed6d48eb35c107fa1f4e0a_HeadFront.png",
    "51ed6d69eb35c107fa1f4e0e_HeadFront.png",
    "51_HeadFront.png",
    "52_HeadFront.png",
    "53_HeadFront.png",
    "54_HeadFront.png",
    "55_HeadFront.png",
    "56_HeadFront.png",
    "57_HeadFront.png",
    "58_HeadFront.png",
    "59_HeadFront.png",
    "5_HeadFront.png",
    "60_HeadFront.png",
    "61_HeadFront.png",
    "62_HeadFront.png",
    "63_HeadFront.png",
    "64_HeadFront.png",
    "65_HeadFront.png",
    "66_HeadFront.png",
    "67_HeadFront.png",
    "68_HeadFront.png",
    "69_HeadFront.png",
    "6_HeadFront.png",
    "70_HeadFront.png",
    "71_HeadFront.png",
    "72_HeadFront.png",
    "73_HeadFront.png",
    "74_HeadFront.png",
    "75_HeadFront.png",
    "76_HeadFront.png",
    "77_HeadFront.png",
    "78_HeadFront.png",
    "79_HeadFront.png",
    "7_HeadFront.png",
    "80_HeadFront.png",
    "81_HeadFront.png",
    "82_HeadFront.png",
    "83_HeadFront.png",
    "84_HeadFront.png",
    "85_HeadFront.png",
    "86_HeadFront.png",
    "87_HeadFront.png",
    "88_HeadFront.png",
    "89_HeadFront.png",
    "8_HeadFront.png",
    "90_HeadFront.png",
    "91_HeadFront.png",
    "92_HeadFront.png",
    "93_HeadFront.png",
    "94_HeadFront.png",
    "95_HeadFront.png",
    "96_HeadFront.png",
    "97_HeadFront.png",
    "98_HeadFront.png",
    "99_HeadFront.png",
    "9_HeadFront.png"
];

export default AvatarHeads;
