const formatList = (
    list: string[]
): string => {
    switch (list.length) {
        case 0:
            return '';
        case 1:
            return list[0];
        case 2:
            return `${list[0]} and ${list[1]}`;
        default:
            const last = list.pop();
            return `${list.join(', ')}, and ${last}`;
    }
}

export default formatList;
