import { Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useDialogManager } from "../global/dialog";
import { useCallback } from "react";
import UserProfileDialog from "../user/userprofiledialog";

export default function LoggedInMenu(): JSX.Element {
    const router = useRouter();
    const { addDialog, onCloseDialog } = useDialogManager();
    const { data: session } = useSession();
    const userId = session.user.id;

    const viewProfile = useCallback(
        () => {
            addDialog(
                <UserProfileDialog
                    userId={userId}
                    onClose={onCloseDialog}
                />,
            );
        },
        [addDialog, onCloseDialog, userId],
    );

    return <Menu>
        <MenuItem
            text='Edit Profile'
            onClick={() => router.push('/user/editprofile')}
        />
        <MenuItem
            text='View Profile'
            onClick={viewProfile}
        />
        <MenuDivider />
        <MenuItem
            text='Sign Out'
            onClick={() => signOut()}
        />
    </Menu>;
}
