import { getThemeClass, useTheme } from "../../../global/theme";
import { Dialog, DialogBody } from "@blueprintjs/core";
import PlayerAvatar from "../../../user/avatar";
import { BotDifficulty } from "../../../../src/server/models/game/bot/enum/difficulty";
import { ApiSeat } from "../../../../src/types/apigame";
import styles from './botprofiledialog.module.css';
import { BOT_AVATAR } from "../../../../src/models/game/bot";

type Props = {
    seatObject: ApiSeat;
    onClose: () => void;
}

export default function BotProfileDialog(
    {
        seatObject,
        onClose,
    }: Props
) {
    const { theme } = useTheme();

    return (
        <Dialog
            className={getThemeClass(theme)}
            isOpen={true}
            onClose={onClose}
            title='Profile'
        >
            <DialogBody>
                <div className={styles.container}>
                    <PlayerAvatar
                        className={styles.avatar}
                        src={BOT_AVATAR}
                        title={seatObject.name}
                    />
                    <div className={styles.name}>
                        {seatObject.name}
                    </div>
                    <div>
                        <b>Difficulty:</b> {BotDifficulty[seatObject.bot.difficulty]}
                    </div>
                </div>
            </DialogBody>
        </Dialog>
    );
}
