import { CombatResult } from "../../combat";
import { GameChatChannel } from "../../enum/game";
import { ApiCountryExtraData } from "../../types/apigame";

export enum ActionId {
    None = 0,
    CreateGame,
    JoinGame,
    AssignSeat,
    Start,
    AssignCountry, // 5
    AwardUnits,
    PlaceUnits,
    Attack,
    Capture,
    AwardCards, // 10
    GameOver,
    TransferUnits,
    TurnInCards,
    EliminatePlayer,
    InvitedGame, // 15
    CancelGame,
    Surrender,
    Fogged,
    FoggedAttack,
    FoggedTransferUnits, // 20
    AssignAssassinTarget,
    AddBot,
    CatanRoll,
    Chat,
    BeginTurn, // 25
    Nudge,
    PlaceCapital,
    DisappearCountry,
    BlizzardCountry,
    HiddenAwardCards, // 30
    Hidden,
    AcceptInvite,
    InviteEmail,
};

export const REPLAY_IGNORE_ACTION_IDS = [
    ActionId.Fogged,
    ActionId.Hidden,
];

export type Action =
    NoneAction |
    CreateGameAction |
    JoinGameAction |
    AssignSeatAction |
    StartAction |
    AssignCountryAction |
    AwardUnitsAction |
    PlaceUnitsAction |
    AttackAction |
    CaptureAction |
    AwardCardsAction |
    GameOverAction |
    TransferUnitsAction |
    TurnInCardsAction |
    EliminatePlayerAction |
    InvitedGameAction |
    CancelGameAction |
    SurrenderAction |
    FoggedAction |
    FoggedAttackAction |
    FoggedTransferUnitsAction |
    AssignAssassinTargetAction |
    AddBotAction |
    CatanRollAction |
    ChatAction |
    BeginTurnAction |
    NudgeAction |
    PlaceCapitalAction |
    DisappearCountryAction |
    BlizzardCountryAction |
    HiddenAwardCardsAction |
    HiddenAction;

export type ChatActions = ChatAction | NudgeAction;

export type ActionData =
    CreateGameActionData |
    AssignSeatActionData |
    AssignCountryActionData |
    AwardUnitsActionData |
    PlaceUnitsActionData |
    AttackActionData |
    CaptureActionData |
    AwardCardsActionData |
    GameOverActionData |
    TransferUnitsActionData |
    TurnInCardsActionData |
    EliminatePlayerActionData |
    FoggedAttackActionData |
    FoggedTransferUnitsActionData |
    AssignAssassinTargetActionData |
    CatanRollActionData |
    ChatActionData |
    BeginTurnActionData |
    NudgeActionData |
    PlaceCapitalActionData |
    DisappearCountryActionData |
    BlizzardCountryActionData |
    HiddenAwardCardsActionData;

interface IAction {
    actionId: ActionId;
    id: number;
    userId: number;
    data?: ActionData;
    ts: string;
};

export interface NoneAction extends IAction {
    actionId: ActionId.None;
};
export interface CreateGameAction extends IAction {
    actionId: ActionId.CreateGame;
    data: CreateGameActionData;
}
export interface JoinGameAction extends IAction {
    actionId: ActionId.JoinGame;
}
export interface AssignSeatAction extends IAction {
    actionId: ActionId.AssignSeat;
    data: AssignSeatActionData;
}
export interface StartAction extends IAction {
    actionId: ActionId.Start;
}
export interface AssignCountryAction extends IAction {
    actionId: ActionId.AssignCountry;
    data: AssignCountryActionData;
}
export interface AwardUnitsAction extends IAction {
    actionId: ActionId.AwardUnits;
    data: AwardUnitsActionData;
}
export interface PlaceUnitsAction extends IAction {
    actionId: ActionId.PlaceUnits;
    data: PlaceUnitsActionData;
}
export interface AttackAction extends IAction {
    actionId: ActionId.Attack;
    data: AttackActionData;
}
export interface CaptureAction extends IAction {
    actionId: ActionId.Capture;
    data: CaptureActionData;
}
export interface AwardCardsAction extends IAction {
    actionId: ActionId.AwardCards;
    data: AwardCardsActionData;
}
export interface GameOverAction extends IAction {
    actionId: ActionId.GameOver;
    data: GameOverActionData;
}
export interface TransferUnitsAction extends IAction {
    actionId: ActionId.TransferUnits;
    data: TransferUnitsActionData;
}
export interface TurnInCardsAction extends IAction {
    actionId: ActionId.TurnInCards;
    data: TurnInCardsActionData;
}
export interface EliminatePlayerAction extends IAction {
    actionId: ActionId.EliminatePlayer;
    data: EliminatePlayerActionData;
}
export interface InvitedGameAction extends IAction {
    actionId: ActionId.InvitedGame;
}
export interface CancelGameAction extends IAction {
    actionId: ActionId.CancelGame;
}
export interface SurrenderAction extends IAction {
    actionId: ActionId.Surrender;
}
export interface FoggedAction extends IAction {
    actionId: ActionId.Fogged;
}
export interface FoggedAttackAction extends IAction {
    actionId: ActionId.FoggedAttack;
    data: FoggedAttackActionData;
}
export interface FoggedTransferUnitsAction extends IAction {
    actionId: ActionId.FoggedTransferUnits;
    data: FoggedTransferUnitsActionData;
}
export interface AssignAssassinTargetAction extends IAction {
    actionId: ActionId.AssignAssassinTarget;
    data: AssignAssassinTargetActionData;
}
export interface AddBotAction extends IAction {
    actionId: ActionId.AddBot;
}
export interface CatanRollAction extends IAction {
    actionId: ActionId.CatanRoll;
    data: CatanRollActionData;
}
export interface ChatAction extends IAction {
    actionId: ActionId.Chat;
    data: ChatActionData;
}
export interface BeginTurnAction extends IAction {
    actionId: ActionId.BeginTurn;
    data: BeginTurnActionData;
}
export interface NudgeAction extends IAction {
    actionId: ActionId.Nudge;
    data: NudgeActionData;
}
export interface PlaceCapitalAction extends IAction {
    actionId: ActionId.PlaceCapital;
    data: PlaceCapitalActionData;
}
export interface DisappearCountryAction extends IAction {
    actionId: ActionId.DisappearCountry;
    data: DisappearCountryActionData;
}
export interface BlizzardCountryAction extends IAction {
    actionId: ActionId.BlizzardCountry;
    data: BlizzardCountryActionData;
}

export interface HiddenAwardCardsAction extends IAction {
    actionId: ActionId.HiddenAwardCards;
    data: HiddenAwardCardsActionData;
}

export interface HiddenAction extends IAction {
    actionId: ActionId.Hidden;
}

export interface AcceptInviteAction extends IAction {
    actionId: ActionId.AcceptInvite;
}

export interface InviteEmailAction extends IAction {
    actionId: ActionId.InviteEmail;
}

export const GlobalActionIds = [
    ActionId.CreateGame,
    ActionId.JoinGame,
    ActionId.AssignSeat,
    ActionId.Start,
    ActionId.GameOver,
    ActionId.InvitedGame,
    ActionId.EliminatePlayer,
    ActionId.CancelGame,
    ActionId.Surrender,
    ActionId.Chat,
    ActionId.BeginTurn,
    ActionId.Nudge,
    ActionId.BlizzardCountry,
    ActionId.AcceptInvite,
    ActionId.InviteEmail,
];

export const ActionIdsWithSeatData = {
    [ActionId.AssignSeat]: ['seatNumber'],
    [ActionId.AssignCountry]: ['seatNumber'],
    [ActionId.AssignAssassinTarget]: ['assassinSeatNumber', 'targetSeatNumber'],
    [ActionId.AwardUnits]: ['seatNumber'],
    [ActionId.PlaceUnits]: ['seatNumber'],
    [ActionId.Attack]: ['fromSeatNumber', 'toSeatNumber'],
    [ActionId.CatanRoll]: ['seatNumber'],
    [ActionId.FoggedAttack]: ['seatNumber'],
    [ActionId.FoggedTransferUnits]: ['seatNumber'],
    [ActionId.Capture]: ['seatNumber'],
    [ActionId.AwardCards]: ['seatNumber'],
    [ActionId.GameOver]: ['seatNumber'],
    [ActionId.TransferUnits]: ['seatNumber'],
    [ActionId.TurnInCards]: ['seatNumber'],
    [ActionId.EliminatePlayer]: ['seatNumber', 'deadSeatNumber'],
    [ActionId.Chat]: ['seatNumber'],
    [ActionId.BeginTurn]: ['seatNumber'],
    [ActionId.DisappearCountry]: ['seatNumber'],
    [ActionId.Nudge]: ['fromSeatNumber', 'toSeatNumber'],
    [ActionId.PlaceCapital]: ['seatNumber'],
    [ActionId.HiddenAwardCards]: ['seatNumber'],
    [ActionId.AcceptInvite]: ['seatNumber'],
};

export type BonusCalculation = {
    countryBonus: number;
    continentBonus: number;
    cardSetBonus: number;
    eliminationBonus: number;
    catanBonus?: number;
    capitalBonus?: number;
};

// ActionDatas
export type CreateGameActionData = {
    mapId: number;
};

export type AssignSeatActionData = {
    color: number;
    name: string;
    seatNumber: number;
    team?: number;
};

export type AssignCountryActionData = {
    countryId: number;
    seatNumber: number;
    units: number;
    extraData?: ApiCountryExtraData;
    revealCountries?: AssignCountryActionData[];
};

export type AwardUnitsActionData = {
    seatNumber: number;
    units: number;
    bonusCalculation: BonusCalculation;
};

export type PlaceUnitsActionData = {
    countryId: number;
    units: number;
    seatNumber: number;
};

export type AttackActionData = {
    fromCountryId: number;
    fromSeatNumber: number;
    fromUnits: number;
    fromUnitsTotal: number;
    toCountryId: number;
    toSeatNumber: number;
    toUnits: number;
    toUnitsTotal: number;
    result: CombatResult;
    revealCountries?: AssignCountryActionData[];
};

export type CaptureActionData = {
    countryId: number;
    seatNumber: number;
    revealCountries?: AssignCountryActionData[];
    fogCountries?: number[];
    bonusUnits?: number;
};

export type AwardCardsActionData = {
    seatNumber: number;
    cardIds: number[];
};

export type GameOverActionData = {
    seatNumber: number;
};

export type TransferUnitsActionData = {
    seatNumber: number;
    fromCountryId: number;
    toCountryId: number;
    units: number;
};

export type TurnInCardsActionData = {
    seatNumber: number;
    cardIds: number[];
    units: number;
};

export type EliminatePlayerActionData = {
    seatNumber: number;
    deadSeatNumber: number;
};

export type FoggedAttackActionData = {
    countryId: number;
    fromUnits: number;
    seatNumber: number;
    units: number;
};

export type FoggedTransferUnitsActionData = {
    countryId: number;
    units: number;
    seatNumber: number;
};

export type AssignAssassinTargetActionData = {
    assassinSeatNumber: number;
    targetSeatNumber: number;
}

export type CatanRollActionData = {
    seatNumber: number;
    rolls: number[];
};

export type ChatActionData = {
    seatNumber: number;
    channel: GameChatChannel;
    text: string;
};

export type BeginTurnActionData = {
    seatNumber: number,
    fogCountries?: number[];
};

export type NudgeActionData = {
    fromSeatNumber: number;
    toSeatNumber: number;
};

export type PlaceCapitalActionData = {
    seatNumber: number;
    countryId: number;
};

export type DisappearCountryActionData = {
    attackerSeatNumber: number;
    countryId: number;
    seatNumber: number;
    units: number;
    fogCountries?: number[];
};

export type BlizzardCountryActionData = {
    countryId: number;
};

export type HiddenAwardCardsActionData = {
    seatNumber: number;
    numCards: number;
}

export type InviteEmailActionData = {
    email: string;
};
