import MapShapeSVG from "../../../map/shape";
import { MapShape } from "../../../map/shapes";
import styles from '../playerlist.module.css';

type Props = {
    color: string;
    shape: MapShape;
}

export function createPlayerListItemIconTeam(
    color: string,
    shape: MapShape,
): JSX.Element {
    return <PlayerListItemIconTeam
        color={color}
        shape={shape}
    />;
}

export default function PlayerListItemIconTeam({
    color,
    shape,
}: Props): JSX.Element {
    return <MapShapeSVG
        fillColor={color}
        shape={shape}
        size={16}
        strokeColor={'white'}
        strokeWidth={'1px'}
        wrapper={'span'}
    />;
}
