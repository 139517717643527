import { ChatAction } from "../models/game/action";
import { ApiGame } from "../types/apigame";

export enum GameFog {
    None = 0,
    Light,
    Heavy,
};

export enum GamePlaceUnitsState {
    Normal = 0,
    JustUnits = 1,
    BlindAtOnceUnits = 2,
};

export enum GameRulesUnitPlacement {
    Static = 0,
    Risk = 1,
    BlindAtOnceUnits = 2,
};

export enum GameRulesTeamSelection {
    Automatic = 0,
    Manual = 1,
};

export enum GameRulesTeamTransfers {
    None = 0,
    ToTeammate = 1,
    MoveTeammate = 2,
};

export enum GameRulesTeamDefense {
    Fight = 0,
    InstantlyLose = 1,
    JoinTeammate = 2,
};

export enum GameRulesWinCondition {
    Default = 0,
    Assassin = 1,
    Catan = 2,
    Capitals = 3,
    Percent = 4,
};

export enum GamePlayerType {
    User = 0,
    Bot = 1,
};

export enum GameChatChannel {
    Global = 0,
    Team,
};

export enum GameSpecialSeats {
    Neutral = -1,
    Fogged = -2,
    Invisible = -3,
    Blizzard = -4,
};

export type ChatError = {
    status: 'error';
    message: string;
};

export type ChatSuccess = {
    status: 'success';
    game: ApiGame;
    action: ChatAction;
};

export type ChatResponse = ChatError | ChatSuccess;

export enum GamePrivacy {
    InviteOnly = 0,
    AnyoneCanJoin,
};

export enum GameUnseenActionsState {
    None = 0,
    ChatsOnly = 1,
    ReplayAvailable = 2,
};
