import GameModeStandard from "./standard";
import { createPlayerListItemIconAssassin } from "../../../../components/game/play/playerlist/icons/assassin";
import { ApiCountry } from "../../../types/apigame";

/**
 * Assassin game mode!
 *
 * Each player is assigned a secret target at the start of the game.
 *
 * Players only know who their target is but do not know the target
 * of any other player in the game.
 *
 * First to kill their target wins the game!
 *
 * If you kill the target of another player, you become that player's target!
 */
export default class GameModeAssassin extends GameModeStandard {
    override getGameModeName(): string {
        return 'Assassin';
    }

    override getGameModeDescription(): string {
        return 'Eliminate your secret target first to win the game!';
    }

    override getGameModeIconName(): string {
        return 'assassin';
    }

    public override isGameOver(countryToExclude?: ApiCountry): boolean {
        // Mapping of killer seat => target seat
        const assassinData: { [key: number]: number } = this.gameController.game.extraData?.assassinData;

        if (!assassinData) {
            return false;
        }

        const turn: number = this.gameController.game.turn;
        const target: number = assassinData[turn];

        if (target == null) {
            return false;
        }

        return this.gameController
            .getCountriesForSeat(target)
            .filter((country) => country.countryId !== countryToExclude?.countryId)
            .length === 0;
    }

    public override getPlayerListIconForSeat(seatNumber: number, localPlayerSeatNumber: number | undefined): any {
        const assassinData = this.gameController.game.extraData?.assassinData;

        if (!assassinData || localPlayerSeatNumber == undefined) {
            return null;
        }

        if (assassinData[localPlayerSeatNumber] === seatNumber) {
            return createPlayerListItemIconAssassin();
        }

        return null;
    }
}
