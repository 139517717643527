import BotProfileDialog from "../playerlist/botprofiledialog";
import UserProfileDialog from "../../../user/userprofiledialog";
import { ApiSeat } from "../../../../src/types/apigame"

type Props = {
    seatObject: ApiSeat;
    onClose: () => void;
}

export default function PlayerProfileDialog(
    {
        seatObject,
        onClose,
    }: Props,
): JSX.Element {
    if (seatObject.userId) {
        return <UserProfileDialog
            userId={seatObject.userId}
            onClose={onClose}
        />;
    } else {
        return <BotProfileDialog
            seatObject={seatObject}
            onClose={onClose}
        />
    }
}
