export type MapShape = {
    file: string;
    id: number;
    offsetX?: number;
    offsetY?: number;
};

export const MAP_SHAPE_FOG: MapShape = {
    "file": "fog.svg",
    "id": -1,
};

const MAP_SHAPES: MapShape[] = [
    {
        "file": "circle.svg",
        "id": 1,
    },
    {
        "file": "triangle.svg",
        "id": 2,
        offsetY: -0.08,
    },
    {
        "file": "square.svg",
        "id": 3
    },
    {
        "file": "cloud.svg",
        "id": 4,
        offsetY: 0.1,
    },
    {
        "file": "diamond.svg",
        "id": 5
    },
    {
        "file": "hexagon.svg",
        "id": 6
    },
    {
        "file": "club.svg",
        "id": 7
    },
    {
        "file": "heart.svg",
        "id": 8
    },
    {
        "file": "spade.svg",
        "id": 9
    },
    {
        "file": "star.svg",
        "id": 10
    }
];

export function getMapShapeUrl(shape: MapShape): string {
    return `/images/teamshapes/${shape.file}`;
}

export default MAP_SHAPES;
