'use client';

import Link, { LinkProps } from 'next/link';
import { Icon, IconName } from '@blueprintjs/core';
import { AnchorHTMLAttributes, ReactNode } from 'react';

type Props = LinkProps & AnchorHTMLAttributes<HTMLAnchorElement> & {
    minimal?: boolean
    icon?: IconName;
    children?: ReactNode;
}

export default function SAnchorButton(props: Props) {
    const {minimal, icon, children} = props;
    let kids = <>{children}</>
    if (icon) {
        kids = <>
            <Icon icon={icon}/>
            {children}
        </>;
    }
    const classes = ['bp5-button'];
    if (minimal) {
        classes.push('bp5-minimal');
    }

    props = {...props, minimal: undefined, icon: undefined, children: undefined};
    return <Link
        {...props}
        className={classes.join(' ')}
        >
            {kids}
        </Link>
}
