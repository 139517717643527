import { UserProfileUser } from "../../src/models/socket/profile"
import PlayerAvatar from "./avatar";
import styles from './playeravatar.module.css';

type Props = {
    user: UserProfileUser;
    className?: string;
}

export default function UserProfileComponent(
    {
        user,
        className,
    }: Props
) {
    className = (className ?? '') + ' ' + styles.small;
    return <div className={className}>
        <PlayerAvatar
            className={styles.avatar}
            src={user.avatar}
            title={user.name}
        />
        <span>{user.name}</span>
    </div>;
}
