import React, { CSSProperties } from "react";
import { PresenceStatusType } from "../../src/server/socket/types"
import { Theme } from "./theme";
import styles from './presenceindicator.module.css';

type Props = {
    className?: string;
    style?: CSSProperties;
    text?: React.ReactNode;
    theme: Theme;
    type: PresenceStatusType;
};

export default function PresenceIndicator(
    {
        className,
        style,
        text,
        theme,
        type,
    }: Props,
): JSX.Element {
    const typeString: string = PresenceStatusType[type];

    let themeClass: string;
    if (theme === Theme.Dark) {
        themeClass = '-dark';
    } else {
        themeClass = '';
    }

    const baseClass = `indicator-${typeString.toLowerCase()}${themeClass}`;
    const classList = [styles.indicator, styles[baseClass]];
    if (className) {
        classList.push(className);
    }

    return <div
        className={classList.join(' ')}
        style={style}
    >
        &#x2B24;
        {text}
    </div>
}
