import Head from "next/head";
import { Theme, useTheme } from "./theme";

export default function SpqrHead() {
    const theme = useTheme();

    return <Head>
            <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
            <meta name="viewport" content="width=832" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="msapplication-TileColor" content={theme.theme === Theme.Dark ? "#2f343c" : "#ffffff"} />
            <meta name="theme-color" content={theme.theme === Theme.Dark ? "#2f343c" : "#ffffff"} />
        </Head>
}
