import { Button } from "@blueprintjs/core";
import { useState } from "react";
import LoginDialog from "../login/logindialog";

export default function NavbarSignInButtonContainer() {
    const [isOpen, setIsOpen] = useState(false);

    const onClickSignIn = () => {
        setIsOpen(true);
    }

    const onClose = () => {
        setIsOpen(false);
    }

    return (
        <Button onClick={onClickSignIn}>
            SIGN IN
            <LoginDialog
                isOpen={isOpen}
                onClose={onClose}
            />
        </Button>
    )
}
