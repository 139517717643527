import { UserProfileStats } from "../../src/models/socket/profile"
import styles from './userprofiledialogcomponent.module.css';

type Props = {
    stats?: UserProfileStats;
};

export default function UserProfileDialogStats(
    {
        stats,
    }: Props,
): JSX.Element {
    const gamesPlayed = stats?.gamesPlayed ?? 0;
    const gamesWon = stats?.gamesWon ?? 0;
    const unitsKilled = stats?.unitsKilled ?? 0;
    const unitsLost = stats?.unitsLost ?? 0;

    let gamesWonPercent: number;
    if (gamesPlayed > 0) {
        gamesWonPercent = Math.round(gamesWon * 100 / gamesPlayed);
    } else {
        gamesWonPercent = 0;
    }

    return <div className={styles.statsTable}>
        <div className={styles.statsTableRow}>
            <div className={styles.statsTableRowName}>
                Games Played:
            </div>
            <div className={styles.statsTableRowValue}>
                {gamesPlayed}
            </div>
        </div>
        <div className={styles.statsTableRow}>
            <div className={styles.statsTableRowName}>
                Games Won:
            </div>
            <div className={styles.statsTableRowValue}>
                {gamesWon} ({gamesWonPercent}%)
            </div>
        </div>
        <div className={styles.statsTableRow}>
            <div className={styles.statsTableRowName}>
                Units Killed:
            </div>
            <div className={styles.statsTableRowValue}>
                {unitsKilled}
            </div>
        </div>
        <div className={styles.statsTableRow}>
            <div className={styles.statsTableRowName}>
                Units Lost:
            </div>
            <div className={styles.statsTableRowValue}>
                {unitsLost}
            </div>
        </div>
    </div>
}
