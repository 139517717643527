import { Dialog, DialogBody } from "@blueprintjs/core";
import { getThemeClass, useTheme } from "../../global/theme";
import styles from './settings.module.css';
import SettingsTheme from "./theme";
import SettingsVolume from "./volume";
import SettingsBackgroundColor from "./backgroundcolor";
import SettingsUnitTextSize from "./unittextsize";

type Props = {
    isOpen: boolean;
    onClose: () => void;
}

export default function SettingsDialog({
    isOpen,
    onClose,
}: Props): JSX.Element {
    const { theme } = useTheme();

    return <Dialog
        className={getThemeClass(theme)}
        isOpen={isOpen}
        onClose={onClose}
        title='Settings'
    >
        <DialogBody>
            <div className={styles.settingsContent}>
                <SettingsVolume />
                <SettingsTheme />
                <SettingsBackgroundColor />
                <SettingsUnitTextSize />
            </div>
        </DialogBody>
    </Dialog>
}
