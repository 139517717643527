'use client';

import { createContext, useCallback, useContext, useRef, useState } from 'react';

type DialogManagerContextProps = {
    addDialog: (dialog: JSX.Element) => void;
    onCloseDialog: () => void;
}

const DialogManagerContext = createContext<DialogManagerContextProps>({
    addDialog: () => { },
    onCloseDialog: () => { },
});

export default function DialogManager({ children }) {
    const [dialogsState, setDialogsState] = useState<JSX.Element[]>([]);

    const addDialog = useCallback(
        (dialog: JSX.Element): void => {
            setDialogsState(
                (oldState) => [...oldState, dialog],
            );
        },
        [setDialogsState],
    );

    const onCloseDialog = useCallback(
        () => {
            setDialogsState(
                (oldState) => oldState.slice(1),
            );
        },
        [setDialogsState],
    );

    const getCurrentDialog = useCallback(
        () => {
            return dialogsState[0];
        },
        [dialogsState],
    );

    const value: DialogManagerContextProps = { addDialog, onCloseDialog };

    return (
        <DialogManagerContext.Provider value={value}>
            <>
                {children}
                {getCurrentDialog()}
            </>
        </DialogManagerContext.Provider>
    );
}

export function useDialogManager() {
    return useContext(DialogManagerContext);
}
