import { NumericInput } from "@blueprintjs/core";
import { RuleNumber } from "../../../src/types/rule";

type Props = {
    isDisabled?: boolean;
    isEditable: boolean;
    onChange: (any) => void;
    rule: RuleNumber;
    value: any;
};

export default function GameRuleNumber({
    isDisabled,
    isEditable,
    onChange,
    rule,
    value,
}: Props) {
    if (!isEditable) {
        return <div>{value}</div>
    }

    return <NumericInput
        disabled={isDisabled}
        id={rule.key}
        onValueChange={(newValue: number) => onChange(newValue)}
        value={value}
    />
}
