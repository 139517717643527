enum SoundId {
    Attack,
    Cancel,
    Capture,
    Change,
    Click,
    Close,
    EliminatePlayer,
    Fortify,
    FortifyTerritory,
    PlaceUnits,
    PlaceUnitsTerritory,
    Popup,
    Select,
    Transfer,
    Undo,
    Win,
};

// default sound file is the string SoundId in lowercase, use this to override
// the defualt value or to use multiple sounds
export const SoundFiles = {
    [SoundId.Attack]: [
        'attack.mp3',
        'attack2.mp3',
        'attack3.mp3',
        'attack4.mp3',
        'attack5.mp3',
    ],
    [SoundId.Fortify]: ['placeunits.mp3'],
    [SoundId.FortifyTerritory]: ['placeunitsterritory.mp3'],
    [SoundId.Transfer]: ['placeunitsterritory.mp3'],
}

export default SoundId;
