import { ApiGame } from "../../src/types/apigame";
import GamesListGame from "./gameslist/gameslistgame";

export enum GameGamesListViewMode {
    Standard = 0,
    Compact = 1,
}

type Props = {
    games: ApiGame[];
    userId: number;
}

export default function GameGamesList(
    {
        games,
        userId,
    }: Props,
) {
    return (
        <div>
            {
                games.map(
                    (game) => (
                        <GamesListGame key={game.id} game={game} userId={userId} />
                    ),
                )
            }
        </div>
    );
}
