const pluralize = (
    num: number,
    text: string,
    plural: string = 's',
): string => {
    return num === 1
        ? `${num} ${text}`
        : `${num} ${text}${plural}`;
}

export default pluralize;
