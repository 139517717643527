import { InputGroup } from "@blueprintjs/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { RuleNumberArray } from "../../../src/types/rule";

function toNumberArray(str: string): number[] {
    // verify the input is a valid array
    const valueAsNumberArray = JSON.parse(`[${str}]`);

    // verify the array is all numbers
    for (const entry of valueAsNumberArray) {
        if (!Number.isInteger(entry)) {
            throw new Error();
        }
    }
    return valueAsNumberArray;
}

type Props = {
    isDisabled?: boolean;
    isEditable: boolean;
    onChange: (any) => void;
    rule: RuleNumberArray;
    value: number[];
};

export default function GameRuleNumberArray({
    isDisabled,
    isEditable,
    onChange,
    rule,
    value,
}: Props) {
    const [localValue, setLocalValue] = useState<string>(value.join(', '));
    const [isError, setIsError] = useState<boolean>(false);
    const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout> | null>(null);
    const inputRef = useRef<HTMLInputElement>();

    const isNewValues = useCallback(
        (valueAsNumberArray: number[]): boolean => {
            // verify the array is different from the passed in array
            if (value.length === valueAsNumberArray.length && value.every(
                (v, idx) => v === valueAsNumberArray[idx]
            )) {
                return false;
            }
            return true;
        },
        [value],
    );

    useEffect(
        () => {
            try {
                const valueAsNumberArray = toNumberArray(localValue);

                // the input is valid
                setIsError(false);

                // verify the array is different from the passed in array
                if (!isNewValues(valueAsNumberArray)) {
                    return;
                }

                // set a timer to save the values after a second of not changing
                const timer = setTimeout(() => {
                    onChange(valueAsNumberArray);
                    setTimerId(null);
                }, 1000);
                setTimerId(timer);

                // when unmounted (probably because the localValue changed),
                // remove the previous timer
                return () => {
                    clearTimeout(timer);
                    setTimerId(null);
                }
            } catch (err) {
                // the localValue is not valid
                setIsError(true);
            }

        },
        [value, localValue, setIsError, onChange, isNewValues],
    );

    const onBlur = useCallback(
        () => {
            if (timerId) {
                clearTimeout(timerId);
                setTimerId(null);
            }
            try {
                const valueAsNumberArray = toNumberArray(localValue);
                if (isNewValues(valueAsNumberArray)) {
                    onChange(valueAsNumberArray);
                }
            } catch (err) {
                setIsError(true);
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }
        },
        [timerId, setTimerId, isNewValues, localValue, onChange, inputRef],
    )

    return <InputGroup
        disabled={isDisabled || !isEditable}
        id={rule.key}
        inputRef={inputRef}
        intent={isError ? 'danger': 'none'}
        onValueChange={(v) => setLocalValue(v)}
        onBlur={onBlur}
        value={localValue}
    />
}
