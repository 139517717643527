import { Dialog } from "@blueprintjs/core";
import { getThemeClass, useTheme } from "../global/theme";
import HomepageAccountFormContainer, { HomepageStatus } from "./accountformcontainer";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    status?: HomepageStatus;
};

export default function LoginDialog(
    {
        isOpen,
        onClose,
        status,
    }: Props,
) {
    const { theme } = useTheme();

    return (
        <Dialog
            className={getThemeClass(theme)}
            isOpen={isOpen}
            onClose={onClose}
        >
            <HomepageAccountFormContainer status={status} />
        </Dialog>
    )
}
