import { useState } from 'react';
import Build from '../../build_info.json';
import styles from './build_info.module.css'
import ChangeLogDialog from './changelogdialog';
import GitLogEntries from '../../public/changelog.json';
import { GitLog } from '../../src/types/gitlog';

export default function BuildInfo() {
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const onClick = () => setDialogIsOpen(true);

    const entries: GitLog = (GitLogEntries as unknown as GitLog).map(
        entry => ({ ...entry, date: new Date(entry.date) })
    );

    return <>
        <div
            id={styles.build_info}
            title={Build.build_date}
            onClick={onClick}
        >
            {Build.git_sha1.substring(0, 6)}
        </div>
        <ChangeLogDialog
            entries={entries}
            isOpen={dialogIsOpen}
            onClose={() => setDialogIsOpen(false)}
        />
    </>
};
