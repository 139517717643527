import { useSession } from "next-auth/react";
import UserSeat from "./userseat";
import { ApiSeat } from "../../src/types/apigame";

type Props = {
    seats: ApiSeat[];
}

export default function UserPlayerList({seats}: Props) {
    const { data: session } = useSession();
    const userId = session?.user?.id;

    if (seats.length === 0) {
        return null;
    }
    if (seats.length === 1) {
        return <UserSeat seatObject={seats[0]} userId={userId} disableAcceptedDimming={true} />;
    }
    seats = seats.slice();
    seats.sort((a, b) => {
        if (a.userId === userId) {
            return Number.NEGATIVE_INFINITY;
        }
        if (b.userId === userId) {
            return Number.POSITIVE_INFINITY;
        }
        return a.seatNumber - b.seatNumber;
    });

    const avatars = seats.map(
        (seatObject, index) => <UserSeat key={index} seatObject={seatObject} userId={userId} disableAcceptedDimming={true} />
    );

    const lastAvatar = avatars.pop();
    if (avatars.length === 1) {
        return <>
            {avatars[0]} <span>and</span> {lastAvatar}
        </>
    }
    const ret = [];
    let c = 1;
    for (const avatar of avatars) {
        ret.push(avatar);
        ret.push(<span key={`c-${c}`}>, </span>);
        c++;
    }
    ret.push(<span key="and">and </span>);
    ret.push(lastAvatar);
    return <>
        {ret}
    </>
}
