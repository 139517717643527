import { useCallback, useEffect } from "react";
import { useRouter } from "next/router";

export default function Notify() {
    const router = useRouter();

    const scriptReady = useCallback(
        () => {
            (async () => {
                const subscription = localStorage.getItem('subscription');
                if (subscription === 'subscribed') {
                    await window.subscribe();
                } else if (subscription === 'unsubscribed') {
                    await window.unsubscribe();
                }
            })();
        }, [],
    );

    useEffect(() => {
        (async () => {
            const response = await fetch('/api/getvapidpubkey');
            localStorage.setItem('vapidPublicKey', await response.text());

            if (!navigator || !navigator.serviceWorker) {
                return;
            }

            navigator.serviceWorker.register('/sw.js');
            navigator.serviceWorker.addEventListener('message', (evt) => {
                console.log('got message:', evt.data);
                if (evt.data.type === 'notificationClick') {
                    router.push(evt.data.url);
                }
            });
        })();

        let scr = document.getElementById('subscriptions-script') as HTMLScriptElement;
        if (scr) {
            scriptReady();
            return;
        }
        scr = document.createElement('script');
        scr.id = 'subscription-script';
        scr.src = '/subscriptions.js';
        scr.async = true;
        scr.onload = scriptReady;
        document.body.appendChild(scr);
    }, [router, scriptReady]);

    return <></>
}
