export default function fetchPost(
    url: string,
    body: any,
): Promise<any> {
    return fetch(
        url,
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify(body),
        },
    ).then(response => response.json());
};