export enum PresenceStatusType {
    Error = -2, // used internally
    Connecting = -1, // used internally
    Offline = 0,
    Online,
    InGame,
};

export type PresenceStatusInGameData = {
    gameId: number;
    gameName?: string;
};

export type PresenceStatus = {
    type: PresenceStatusType;
    data?: PresenceStatusInGameData;
};

import { Socket } from "socket.io";

export enum SocketEvent {
    Connect = 'connect',
    Disconnect = 'disconnect',
    JoinGame = 'joinGame',
    LeaveGame = 'leaveGame',
};

export type SocketEventConnect = {
    userId: number;
    socket: Socket;
}

export type SocketEventDisconnect = {
    userId: number;
    socket: Socket;
}

export type SocketEventJoinGame = {
    userId: number;
    socket: Socket;
    gameId: number;
}

export type SocketEventLeaveGame = {
    userId: number;
    socket: Socket;
    gameId: number;
}
