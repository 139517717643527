'use client';

import { ReactNode, useEffect, useMemo, useState } from "react";
import { getThemeClass, useTheme } from "./theme";
import { Dialog, DialogBody } from "@blueprintjs/core";
import { GitLog } from "../../src/types/gitlog";
import ChangeLogEntry from "./changelogentry";
import { SocketState, useSocket } from "./socket";
import { ClientRequests } from "../../src/enum/socket";
import { UserProfileUser, UserProfilesResponse } from "../../src/models/socket/profile";

type AuthorProfiles = {
    [name: string]: UserProfileUser | null;
};

type Props = {
    entries: GitLog;
    isOpen: boolean;
    onClose: () => void;
};

export default function ChangeLogDialog({
    entries,
    isOpen,
    onClose,
}: Props): ReactNode {
    const { theme } = useTheme();
    const { socket, state } = useSocket();
    const [ authors, setAuthors ] = useState<AuthorProfiles>({});

    useEffect(
        () => {
            // a map of contributor names to userId
            const AuthorData = {
                chad: 1,
                cwalker: 1,
                aportner: 4,
                ckirmse: 2,
                chris: 2,
            };
            if (state !== SocketState.CONNECTED) {
                // turn name => userId to name => null
                setAuthors(
                    Object.fromEntries(
                        Object.entries(AuthorData).map(
                            ([k]) => [k, null]
                        )
                    )
                );
                return () => setAuthors({});
            }
            (async () => {
                const userIds = new Set<number>(Object.values(AuthorData));
                const userProfiles: UserProfilesResponse = await socket.emitWithAck(ClientRequests.GetUserProfiles, [...userIds]);

                setAuthors(
                    Object.fromEntries(
                        Object.entries(AuthorData).map(
                            ([name, userId]) => [
                                name,
                                userProfiles[userId],
                            ]
                        )
                    )
                );
            })();

            return () => setAuthors({});
        },
        [socket, state, setAuthors],
    );

    const body = entries.map(
        entry => {
            const userProfile = authors[entry.author];
            return <ChangeLogEntry entry={entry} userProfile={userProfile} key={entry.hash}/>;
        }
    );

    return (
        <Dialog
            className={getThemeClass(theme)}
            isOpen={isOpen}
            onClose={onClose}
            style={{ minWidth: '640px'}}
            title='Change Log'
        >
            <DialogBody>
                {body}
            </DialogBody>
        </Dialog>
    );
}
