import { ApiGame } from "../../../types/apigame";
import GameFogLight from "./light";

export default class GameFogHeavy extends GameFogLight {
    public override hideFogCountries(
        game: ApiGame,
        seatNumber: number,
    ): void {
        const seatObject = this.g.getSeatObjectBySeatNumber(seatNumber);

        for (const country of game.countries) {
            if (!seatObject || !this.g.gameMode.areAllies(country.seatNumber, seatObject.seatNumber)) {
                this.fogCountry(country);
            }
        }
    }
}
