import Image from 'next/image';
import styles from '../playerlist.module.css';

export function createPlayerListItemIconAssassin(): JSX.Element {
    return <PlayerListItemIconAssassin />;
}

export default function PlayerListItemIconAssassin(): JSX.Element {
    return <Image
        alt="Assassin Icon"
        className={styles.playerListIconAssassin}
        src="/images/playerlisticons/swordicon.svg"
        width={56}
        height={16}
        style={{
            right: '70px',
        }}
    />
};
