'use client';

import { Classes } from '@blueprintjs/core';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { LocalStorageKeys } from '../../src/enum/localstorage';

type ThemeContextProps = {
    theme: Theme;
    setTheme: (theme: Theme) => void;
}

export enum Theme {
    Light = 0,
    Dark,
};

const ThemeContext = createContext<ThemeContextProps>({
    theme: Theme.Light,
    setTheme: (): void => { },
});

export const getThemeClass = (theme: Theme): string => {
    if (theme === Theme.Dark) {
        return Classes.DARK + ' bp5-dark-body';
    }

    return '';
}

export default function ThemeProvider({ children }) {
    const [theme, setTheme] = useLocalStorage<Theme>(LocalStorageKeys.Theme, Theme.Light);
    const themeClass = getThemeClass(theme);
    const value = { theme, setTheme };

    return (
        <ThemeContext.Provider value={value}>
            <div className={themeClass} id='theme'>
                {children}
            </div>
        </ThemeContext.Provider>
    );
}

export function useTheme() {
    return useContext(ThemeContext);
}
