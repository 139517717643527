export type milliseconds = number;

export const ONE_SECOND: milliseconds = 1_000;
export const TWO_SECONDS: milliseconds = 2_000;
export const TEN_SECONDS: milliseconds = 10_000;
export const ONE_MINUTE: milliseconds = 60_000;
export const TEN_MINUTES: milliseconds = 600_000;
export const ONE_HOUR: milliseconds = 3_600_000;
export const TEN_HOURS: milliseconds = 36_000_000;
export const ONE_DAY: milliseconds = 86_400_000;
export const THREE_DAYS: milliseconds = 259_200_000;
export const ONE_WEEK: milliseconds = 604_800_000;
export const THREE_WEEKS: milliseconds = 1_814_400_000;
export const ONE_MONTH: milliseconds = 2_592_000_000;
export const SIX_MONTHS: milliseconds = 15_552_000_000;
export const ONE_YEAR: milliseconds = 31_536_000_000;
export const THREE_YEARS: milliseconds = 94_608_000_000;

export function getApproximateTimeString(timeA: milliseconds, timeB: milliseconds) {
    const diff = timeB - timeA;
    if (diff < TWO_SECONDS) {
        return "a bit";
    }
    if (diff < TEN_SECONDS) {
        return "a few seconds";
    }
    if (diff < ONE_MINUTE) {
        return "less than a minute";
    }
    if (diff < TEN_MINUTES) {
        return "a few minutes";
    }
    if (diff < ONE_HOUR) {
        return "less than an hour";
    }
    if (diff < TEN_HOURS) {
        return "a few hours";
    }
    if (diff < ONE_DAY) {
        return "less than a day";
    }
    if (diff < THREE_DAYS) {
        return "a few days";
    }
    if (diff < ONE_WEEK) {
        return "less than a week";
    }
    if (diff < THREE_WEEKS) {
        return "a few weeks";
    }
    if (diff < ONE_MONTH) {
        return "less than a month";
    }
    if (diff < SIX_MONTHS) {
	return "a few months";
    }
    if (diff < ONE_YEAR) {
        return "less than a year";
    }
    if (diff < THREE_YEARS) {
        return "a few years";
    }
    return "many years";
}

export function getRelativeTimeString(timeA: milliseconds, timeB: milliseconds) {
    let diff = timeB - timeA;
    const str: string[] = [];
    if (diff > ONE_YEAR) {
      const years = Math.floor(diff / ONE_YEAR);
      const s = years === 1 ? "" : "s";
      str.push(`${years} year${s}`);
      diff = diff % ONE_YEAR;
    }
    if (diff > ONE_MONTH) {
      const months = Math.floor(diff / ONE_MONTH);
      const s = months === 1 ? "" : "s";
      str.push(`${months} month${s}`);
      diff = diff % ONE_MONTH;
    }
    if (diff > ONE_WEEK) {
      const weeks = Math.floor(diff / ONE_WEEK);
      const s = weeks === 1 ? "" : "s";
      str.push(`${weeks} week${s}`);
      diff = diff % ONE_WEEK;
    }
    if (diff > ONE_DAY) {
      const days = Math.floor(diff / ONE_DAY);
      const s = days === 1 ? "" : "s";
      str.push(`${days} day${s}`);
      diff = diff % ONE_DAY;
    }
    if (diff > ONE_HOUR) {
      const hours = Math.floor(diff / ONE_HOUR);
      const s = hours === 1 ? "" : "s";
      str.push(`${hours} hour${s}`);
      diff = diff % ONE_HOUR;
    }
    if (diff > ONE_MINUTE) {
      const minutes = Math.floor(diff / ONE_MINUTE);
      const s = minutes === 1 ? "" : "s";
      str.push(`${minutes} minute${s}`);
      diff = diff % ONE_MINUTE;
    }
    if (diff > ONE_SECOND) {
      const seconds = Math.floor(diff / ONE_SECOND);
      const s = seconds === 1 ? "" : "s";
      str.push(`${seconds} second${s}`);
      diff = diff % ONE_SECOND;
    }
    if (str.length > 1) {
        str.splice(-1, 0, "and");
    }

    return str.join(", ");
}
