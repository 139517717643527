import GameRuleBoolean from "../../components/game/rules/boolean";
import GameRuleEnum from "../../components/game/rules/enum";
import GameRuleNumber from "../../components/game/rules/number";
import GameRuleNumberArray from "../../components/game/rules/numberarray";
import { GameFog, GameRulesTeamSelection, GameRulesUnitPlacement, GameRulesTeamTransfers, GameRulesTeamDefense, GameRulesWinCondition } from "../enum/game";
import { AdminRule, AdminRules, Rule, RuleValue, Rules } from "../types/rule";

export const AllRules: Rule[] = [
    {
        key: 'blizzards',
        name: 'Blizzards',
        description: 'Number of blizzards',
        componentClass: GameRuleNumber,
        defaultValue: 0,
        group: 'map',
    },
    {
        key: 'cardBonus.base',
        name: 'Card Bonus - Base',
        description: 'Starting bonus for turning in cards',
        componentClass: GameRuleNumber,
        defaultValue: 4,
        group: 'cards',
    },
    {
        key: 'cardBonus.increment',
        name: 'Card Bonus - Increment',
        description: 'Comma-separated list of numbers added to increase the bonus on turn-ins',
        componentClass: GameRuleNumberArray,
        defaultValue: [2, 2, 2, 2, 3, 5],
        group: 'cards',
    },
    {
        key: 'cardBonus.loop',
        name: 'Card Bonus - Loop',
        description: 'true/false/number of times to loop through increments',
        componentClass: GameRuleBoolean,
        defaultValue: false,
        group: 'cards',
    },
    {
        key: 'cardCapture',
        name: 'Card Capture',
        description: 'Controls whether, upon elimination, killer receives eliminated player\'s cards',
        componentClass: GameRuleBoolean,
        defaultValue: true,
        group: 'cards',
    },
    {
        key: 'startingUnitsPerCountry',
        name: 'Starting Units per Country',
        description: 'How many units each player starts with per initial country',
        componentClass: GameRuleNumber,
        defaultValue: 3,
        group: 'units',
    },
    {
        key: 'completelyHideEnemyCards',
        name: 'Completely Hide Enemy Cards',
        description: 'If true, you will not know when enemies receive cards',
        componentClass: GameRuleBoolean,
        defaultValue: false,
        group: 'cards',
    },
    {
        key: 'countriesPerUnitBonus',
        name: 'Countries per Unit Bonus',
        description: 'Bonus units players receive on turn start per countries occupied',
        componentClass: GameRuleNumber,
        defaultValue: 3,
        group: 'units',
    },
    {
        key: 'enableCountryFill',
        name: 'Enable Country Fill',
        description: 'If true, fill countries with the color of the occupant. If false, use map defaults',
        componentClass: GameRuleBoolean,
        defaultValue: true,
        group: 'map',
    },
    {
        key: 'minUnitsPerTerritory',
        name: 'Minimum Units Per Territory',
        description: 'Minimum units you can leave in a territory',
        componentClass: GameRuleNumber,
        defaultValue: 1,
        group: 'units',
    },
    {
        key: 'eliminationBonusUnits',
        name: 'Elimination Bonus Units',
        description: 'Bonus units for eliminating a player',
        componentClass: GameRuleNumber,
        defaultValue: 0,
        group: 'units',
    },
    {
        key: 'fog',
        name: 'Fog',
        componentClass: GameRuleEnum(GameFog),
        description: 'Higher numbers hide more information during active play',
        defaultValue: GameFog.None,
        group: 'map',
    },
    {
        key: 'forceCardTurninCount',
        name: 'Force Card Turnin Count',
        description: 'Forces users to turn in cards at this count',
        componentClass: GameRuleNumber,
        defaultValue: 5,
        group: 'cards',
    },
    {
        key: 'forceCardTurninDuringAttackCount',
        name: 'Force Card Turnin Count for Elim',
        description: 'Count for forcing users to turn in cards after eliminating a player',
        componentClass: GameRuleNumber,
        defaultValue: 6,
        group: 'cards',
    },
    {
        key: 'maxFortifies',
        name: 'Max Fortifies',
        description: 'Maximum number of fortifies per turn (0 for infinite)',
        componentClass: GameRuleNumber,
        defaultValue: 4,
        group: 'map',
    },
    {
        key: 'neutralPlayer',
        name: 'Neutral Player',
        description: 'Include a Neutral player? This player does not attack or gain units as the game progresses.',
        componentClass: GameRuleBoolean,
        defaultValue: true,
        group: 'map',
    },
    {
        key: 'teams',
        name: 'Teams',
        description: 'Number of teams',
        componentClass: GameRuleNumber,
        defaultValue: 0,
        group: 'teams',
    },
    {
        key: 'teamSelection',
        name: 'Team Selection',
        description: 'Controls how players are assigned to teams',
        componentClass: GameRuleEnum(GameRulesTeamSelection),
        defaultValue: GameRulesTeamSelection.Automatic,
        group: 'teams',
    },
    {
        key: 'teamTransfers',
        name: 'Team Transfers',
        description: 'Controls what type of transfers players can make with respect to teammates',
        componentClass: GameRuleEnum(GameRulesTeamTransfers),
        defaultValue: GameRulesTeamTransfers.ToTeammate,
        group: 'teams',
    },
    {
        key: 'teamPlacement',
        name: 'Team Placement',
        description: 'Controls whether players can place units on territories owned by teammates',
        componentClass: GameRuleBoolean,
        defaultValue: true,
        group: 'teams',
    },
    {
        key: 'teamDefense',
        name: 'Team Defense',
        description: 'Controls how units behave when attacked by teammates',
        componentClass: GameRuleEnum(GameRulesTeamDefense),
        defaultValue: GameRulesTeamDefense.Fight,
        group: 'teams',
    },
    {
        key: 'unitPlacement',
        name: 'Unit Placement',
        description: 'Controls how units are placed at the start of the game',
        componentClass: GameRuleEnum(GameRulesUnitPlacement),
        defaultValue: GameRulesUnitPlacement.Static,
        group: 'units',
    },
    {
        key: 'voids',
        name: 'Voids Probability (0-100)',
        description: 'The probability a void eats a country start of each turn',
        componentClass: GameRuleNumber,
        defaultValue: 0,
        group: 'map',
    },
    {
        key: 'winCondition',
        name: 'Win Condition',
        description: 'Special win condition',
        componentClass: GameRuleEnum(GameRulesWinCondition),
        defaultValue: GameRulesWinCondition.Default,
        group: 'win',
    },
    {
        key: 'defaultAttackDieFaces',
        name: 'Default Attack Die Faces',
        description: 'Number of sides on attack dice if not overridden',
        componentClass: GameRuleNumber,
        defaultValue: 6,
        group: 'dice',
    },
    {
        key: 'defaultDefendDieFaces',
        name: 'Default Defend Die Faces',
        description: 'Number of sides on defense dice if not overridden',
        componentClass: GameRuleNumber,
        defaultValue: 6,
        group: 'dice',
    },
];

export const getRuleValue = (rulesObject: Rules | AdminRules, rule: Rule | AdminRule): RuleValue => {
    let obj = rulesObject;
    const keyPath = rule.key.split('.');

    while (keyPath.length > 0) {
        const nextKey = keyPath.shift();
        obj = obj[nextKey];
    }

    return obj;
}

export const createRules = (rulesArray: Rule[] = AllRules): Rules => {
    const rules = {};

    for (const rule of rulesArray) {
        let obj = rules;
        const keyPath = rule.key.split('.');

        while (keyPath.length > 1) {
            const key = keyPath.shift();

            if (obj[key] == undefined) {
                obj[key] = {};
            }

            obj = obj[key];
        }

        obj[keyPath[0]] = rule.defaultValue;
    }

    return rules;
}

const defaultRules = createRules();

export default defaultRules;
