import { Button, ControlGroup, Slider } from "@blueprintjs/core";
import { useCallback } from "react";
import { useSoundManager } from "../../global/sound/soundmanager";
import SoundId from "../../global/sound/soundid";
import { IconNames } from "@blueprintjs/icons";
import styles from './settings.module.css';

export default function SettingsVolume(): JSX.Element {
    const { playSound, volume, setVolume } = useSoundManager();
    let lastPlayTime = 0;

    const onChange = useCallback(
        (value: number) => {
            const newVolume = value * 0.01;

            setVolume(newVolume);
            if (Date.now() - lastPlayTime > 300) {
                playSound(SoundId.Change, { volume: newVolume });
                // eslint-disable-next-line react-hooks/exhaustive-deps
                lastPlayTime = Date.now();
            }
        },
        [playSound, setVolume],
    );

    const mute = useCallback(
        () => onChange(0),
        [onChange],
    );

    const fullVolume = useCallback(
        () => onChange(100),
        [onChange],
    );

    return <div>
        <div><b>Volume:</b></div>
        <div>
            <ControlGroup>
                <Button
                    disabled={volume <= 0}
                    icon={IconNames.VolumeOff}
                    onClick={mute}
                />
                <Slider
                    labelRenderer={false}
                    min={0}
                    max={100}
                    stepSize={1}
                    value={volume * 100}
                    onChange={onChange}
                    className={styles.settingsVolumeSlider}
                />
                <Button
                    className={styles.settingsVolumeButtonFull}
                    disabled={volume >= 1}
                    icon={IconNames.VolumeUp}
                    onClick={fullVolume}
                />
            </ControlGroup>
        </div>
    </div>;
}
