import { ApiCountry, ApiGame } from "../../../types/apigame";
import GameFogNone from "./none";

export default class GameFogLight extends GameFogNone {
    // modifies game.countries in place
    public hideFogCountries(game: ApiGame, seatNumber: number): void {
        const gameMode = this.g.gameMode;
        const seatObject = this.g.getSeatObjectBySeatNumber(seatNumber);

        for (const country of game.countries) {
            if (
                !seatObject ||
                (
                    !gameMode.areAllies(country.seatNumber, seatObject.seatNumber)
                    && !this.g.isCountryAdjacentToSeat(
                        country.countryId,
                        seatObject.seatNumber,
                    )
                )
            ) {
                this.fogCountry(country);
            }
        }
    }

    protected fogCountry(country: ApiCountry): void {
        country.units = -1;
        country.seatNumber = -1;
    }
}
