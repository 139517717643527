import { createContext, useCallback, useContext, useEffect, useState } from 'react';

const getDefaultPageFocusValue = () => globalThis.document?.hasFocus();

const PageFocusContext = createContext(getDefaultPageFocusValue());

export function PageFocusProvider({ children }) {
    const [isFocused, setIsFocused] = useState(getDefaultPageFocusValue());

    const onFocus = useCallback(
        () => setIsFocused(true),
        [setIsFocused],
    );

    const onBlur = useCallback(
        () => setIsFocused(false),
        [setIsFocused],
    );

    useEffect(
        () => {
            window.addEventListener('focus', onFocus);
            window.addEventListener('blur', onBlur);

            return () => {
                window.removeEventListener('focus', onFocus);
                window.removeEventListener('blur', onBlur);
            }
        },
        [onBlur, onFocus],
    );

    return (
        <PageFocusContext.Provider value={isFocused}>
            {children}
        </PageFocusContext.Provider>
    );
}

export function usePageFocus() {
    return useContext(PageFocusContext);
}
