import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useSocket } from "./socket";
import { GameSocketMessageEventTypes, GameSocketPresenceMessage, IGameSocketNotifyMessage } from "../../src/models/game/socket";
import { PresenceStatus } from "../../src/server/socket/types";

type PresenceContextType = {
    getPresenceForUserId: (userId: number) => PresenceStatus;
}

const PresenceContext = createContext<PresenceContextType>({
    getPresenceForUserId: () => {
        throw new Error('Cannot be used outside PresenceProvider');
    },
});

type PresenceMap = Map<number, PresenceStatus>;

type Props = {
    children: React.ReactNode;
};

export default function PresenceProvider(
    {
        children,
    }: Props,
): JSX.Element {
    const { socket } = useSocket();
    const [presence, setPresence] = useState<PresenceMap>(new Map());

    useEffect(
        () => {
            if (!socket) {
                return;
            }

            const onPresence = (message: GameSocketPresenceMessage) => {
                // this can be called multiple times in the same frame
                // (i.e. before a rerender) if socket.io decides to batch
                // together several presence updates.
                //
                // calling set on the original presence object ensures
                // future calls on the same frame propagate the data
                presence.set(message.userId, message.status);

                // then create a new presence map to rerender children
                const newPresence = new Map(presence);
                setPresence(newPresence);
            }

            const onDisconnect = () => {
                setPresence(new Map());
            }

            socket.on(
                'disconnect',
                onDisconnect,
            );

            socket.on(
                GameSocketMessageEventTypes.Presence,
                onPresence,
            );

            return () => {
                if (!socket) {
                    return;
                }

                socket.off(
                    GameSocketMessageEventTypes.Presence,
                    onPresence,
                );

                socket.off(
                    'disconnect',
                    onDisconnect,
                );
            }
        },
        [socket, presence, setPresence],
    );

    const getPresenceForUserId = useCallback(
        (userId: number) => {
            return presence.get(userId);
        },
        [presence],
    );

    const value = useMemo(
        (): PresenceContextType => ({ getPresenceForUserId }),
        [getPresenceForUserId],
    );

    return <PresenceContext.Provider value={value}>
        {children}
    </PresenceContext.Provider>
}

export function usePresence(): PresenceContextType {
    return useContext(PresenceContext);
}
