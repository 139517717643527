import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import SoundId, { SoundFiles } from './soundid';
import { useLocalStorage } from 'usehooks-ts';
import { Intent, OverlayToaster } from '@blueprintjs/core';
import { LocalStorageKeys } from '../../../src/enum/localstorage';

type SoundManagerContextProps = {
    playSound: (sound: SoundId, params?: SoundManagerPlaySoundParams) => void;
    setVolume: (newVolume: number) => void;
    volume: number;
}

type SoundManagerPlaySoundParams = {
    speed?: number;
    volume?: number;
};

const SoundManagerContext = createContext<SoundManagerContextProps>({
    playSound: () => { },
    setVolume: () => { },
    volume: 1,
});

const getUrlForSound = (filename: string): string => `/sounds/${filename}`;

const getDefaultSound = (soundId: SoundId): string => {
    const enumName: string = SoundId[soundId];
    const filename: string = `${enumName.toLowerCase()}.mp3`;

    return filename;
}

export function SoundManagerProvider({ children }) {
    const audioCache = useRef({});
    const [volume, setVolume] = useLocalStorage<number>(LocalStorageKeys.Volume, 1);
    const [
        hasShownInteractionWarning,
        setHasShownInteractionWarning,
    ] = useState<boolean>(false);

    // Preload sounds and cache them
    useEffect(
        () => {
            for (const soundId of Object.values(SoundId)) {
                // ignore string values of the enum
                if (isNaN(Number(soundId))) {
                    continue;
                }

                const soundFiles = SoundFiles[soundId];

                if (soundFiles === undefined) {
                    const sound = getDefaultSound(soundId as SoundId);
                    audioCache.current[sound] = new Audio(getUrlForSound(sound));
                } else {
                    for (const sound of soundFiles) {
                        audioCache.current[sound] = new Audio(getUrlForSound(sound));
                    }
                }
            }
        },
        [],
    );

    const playSound = useCallback(
        (soundId: SoundId, params?: SoundManagerPlaySoundParams) => {
            let sound;

            if (SoundFiles[soundId] === undefined) {
                sound = getDefaultSound(soundId as SoundId);
            } else {
                const soundFiles = SoundFiles[soundId];
                sound = soundFiles[Math.floor(Math.random() * soundFiles.length)];
            }

            const source = audioCache.current[sound];
            if (!source) {
                console.error(`Could not play audio file`, soundId);
                return;
            }

            const audio: HTMLAudioElement = source.cloneNode();
            audio.volume = params?.volume ?? volume;
            audio.playbackRate = params?.speed ?? 1;

            audio
                .play()
                .catch(
                    (err) => {
                        if (hasShownInteractionWarning) {
                            return;
                        }

                        const toast = OverlayToaster.create();
                        toast.show({
                            intent: Intent.DANGER,
                            message: 'Cannot play sound, try interacting with the page',
                        });

                        setHasShownInteractionWarning(true);
                    },
                );
        },
        [hasShownInteractionWarning, setHasShownInteractionWarning, volume],
    );

    const value = useMemo(
        () => ({
            playSound,
            setVolume,
            volume,
        }),
        [playSound, setVolume, volume],
    );

    return (
        <SoundManagerContext.Provider value={value}>
            {children}
        </SoundManagerContext.Provider>
    );
}

export function useSoundManager() {
    return useContext(SoundManagerContext);
}
