import { HomepageStatus } from "./accountformcontainer";

export default function HomepageBottomText({
    status,
    onClick,
}) {
    const hereText = <a href="#" onClick={ onClick }>here</a>;

    if (status === HomepageStatus.Login) {
        return <div>Click {hereText} to register an account.</div>
    } else {
        return <div>Already have an account? Click {hereText} to log in.</div>
    }
}
