import Card from "./card";

export type FullCard = {
    cardId: number;
    isWild: boolean;
};

/**
 * Returns true if cards are a set. There must be 3 any only 3 cards in
 * the array, and the cards must all match or be unique (including wilds)
 * @param cards
 * @returns
 */
function areCardsASet(cards: FullCard[]): boolean {
    if (cards.length !== 3) {
        throw new Error('You can must turn in only 3 cards');
    }

    const wilds = cards.filter((card) => card.isWild);

    /**
     * If any wilds it must be a set
     * e.g.:
     * (Card A) (Wild)   (Wild)
     * (Card B) (Card B) (Wild) - 3 of a kind
     * (Card A) (Card C) (Wild) - all 3 unique
     */
    if (wilds.length > 0) {
        return true;
    }

    const isThreeOfAKind = cards[0].cardId === cards[1].cardId
        && cards[1].cardId === cards[2].cardId;

    const areThreeUnique = cards[0].cardId !== cards[1].cardId
        && cards[1].cardId !== cards[2].cardId
        && cards[0].cardId !== cards[2].cardId;

    return isThreeOfAKind || areThreeUnique;
}

export function getBestCardSetsHelper(cards: FullCard[]): any {
    if (cards.length < 3) {
        let score = 0;
        for (const card of cards) {
            if (card.isWild) {
                // wild cards are better to still be around so they get some value
                score += 0.1;
            }
        }
        return {
            score,
            indexes: [],
        };
    }

    let bestResult = {
        score: 0,
        indexes: [],
    };

    for (let i1 = 0; i1 < cards.length - 2; ++i1) {
        for (let i2 = i1 + 1; i2 < cards.length - 1; ++i2) {
            for (let i3 = i2 + 1; i3 < cards.length; ++i3) {
                if (!areCardsASet([cards[i1], cards[i2], cards[i3]])) {
                    continue;
                }
                let remainingCards = cards.slice();
                remainingCards.splice(i3, 1);
                remainingCards.splice(i2, 1);
                remainingCards.splice(i1, 1);
                let setScore = 1;
                if (cards[i1].isWild) {
                    setScore -= 0.01 * cards.length;
                }
                if (cards[i2].isWild) {
                    setScore -= 0.01 * cards.length;
                }
                if (cards[i3].isWild) {
                    setScore -= 0.01 * cards.length;
                }
                const remainingResult = getBestCardSetsHelper(remainingCards);
                const thisResult = {
                    score: setScore + remainingResult.score,
                    indexes: [[i1, i2, i3], ...remainingResult.indexes],
                };
                if (thisResult.score > bestResult.score) {
                    bestResult = thisResult;
                }
            }
        }
    }

    return bestResult;
}

export function getBestCardsSetIndexesIfExists(cards: FullCard[]): number[] | null {
    const result = getBestCardSetsHelper(cards);
    if (result.indexes.length === 0) {
        return null;
    }
    const cardSetIndexes = result.indexes[0];
    return [cardSetIndexes[0], cardSetIndexes[1], cardSetIndexes[2]];
}
