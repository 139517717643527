import { HTMLSelect } from "@blueprintjs/core";
import { Theme, useTheme } from "../../global/theme";
import { useCallback } from "react";

export default function SettingsTheme(): JSX.Element {
    const { theme, setTheme } = useTheme();

    const onChange = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            setTheme(
                Number(event.currentTarget.value),
            );
        },
        [setTheme],
    )

    return (
        <div>
            <div><b>Theme:</b></div>
            <div>
                <HTMLSelect
                    options={
                        Object.keys(Theme)
                            .filter((key) => isNaN(Number(key)))
                            .map((key) => ({
                                label: key,
                                value: String(Theme[key])
                            }))
                    }
                    onChange={onChange}
                    value={theme.toString()}
                />
            </div>
        </div>
    );
}
