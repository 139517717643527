import { PresenceStatus, PresenceStatusInGameData, PresenceStatusType } from "../../src/server/socket/types";
import Link from "next/link";
import PresenceIndicator from "../global/presenceindicator";
import { useTheme } from "../global/theme";
import React from "react";

type Props = {
    status: PresenceStatus;
};

export default function UserProfileDialogPresence(
    {
        status,
    }: Props,
): JSX.Element {
    const { theme } = useTheme();

    let text: string | JSX.Element;

    if (status.type === PresenceStatusType.Offline) {
        text = 'Offline';
    } else if (status.type === PresenceStatusType.Online) {
        text = 'Online';
    } else if (status.type === PresenceStatusType.InGame) {
        const inGameData = status.data as PresenceStatusInGameData;
        const gameName = inGameData.gameName ?? inGameData.gameId.toString();
        text = <Link href={`/game/play?id=${inGameData.gameId}`}>
            Playing {gameName}
        </ Link>;
    }

    const textElement = <React.Fragment>
        &nbsp;
        {text}
    </React.Fragment>

    return <PresenceIndicator
        text={textElement}
        theme={theme}
        type={status.type}
    />;
}
