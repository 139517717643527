import GameModeStandard from "./standard";

export default class GameModeCatan extends GameModeStandard {
    override getGameModeName(): string {
        return 'Catan';
    }

    override getGameModeDescription(): string {
        return 'World Domination with Catan-style resource allocation!';
    }

    override getGameModeIconName(): string {
        return 'catan';
    }
}
