export type CountryColor = {
    id: number;
    name: string;
    hex: string;
    rgb: number[];
};

export const COLOR_NEUTRAL: CountryColor = {
    id: -1,
    name: 'Gray',
    hex: '#808080',
    rgb: [
        128,
        128,
        128,
    ],
};

const defaultColors: CountryColor[] = [
    {
        "id": 0,
        "name": "Red",
        "hex": "#FF0000",
        "rgb": [
            255,
            0,
            0
        ]
    },
    {
        "id": 1,
        "name": "Lime",
        "hex": "#00FF00",
        "rgb": [
            0,
            255,
            0
        ]
    },
    {
        "id": 2,
        "name": "Blue",
        "hex": "#0000FF",
        "rgb": [
            0,
            0,
            255
        ]
    },
    {
        "id": 3,
        "name": "Yellow",
        "hex": "#FFFF00",
        "rgb": [
            255,
            255,
            0
        ]
    },
    {
        "id": 4,
        "name": "Cyan",
        "hex": "#00FFFF",
        "rgb": [
            0,
            255,
            255
        ]
    },
    {
        "id": 5,
        "name": "Pink",
        "hex": "#FF00FF",
        "rgb": [
            255,
            0,
            255
        ]
    },
    {
        "id": 8,
        "name": "Maroon",
        "hex": "#800000",
        "rgb": [
            128,
            0,
            0
        ]
    },
    {
        "id": 10,
        "name": "Green",
        "hex": "#008000",
        "rgb": [
            0,
            128,
            0
        ]
    },
    {
        "id": 11,
        "name": "Purple",
        "hex": "#800080",
        "rgb": [
            128,
            0,
            128
        ]
    },
    {
        "id": 12,
        "name": "Teal",
        "hex": "#008080",
        "rgb": [
            0,
            128,
            128
        ]
    },
    {
        "id": 13,
        "name": "Navy",
        "hex": "#000080",
        "rgb": [
            0,
            0,
            128
        ]
    },
    {
        "id": 14,
        "name": "Orange",
        "hex": "#FF8C00",
        "rgb": [
            255,
            140,
            0
        ]
    },
    {
        "id": 15,
        "name": "Brown",
        "hex": "#8B4513",
        "rgb": [
            139,
            69,
            19
        ]
    },
];

export function getColorObjectById(id: number): CountryColor {
    if (id < 0) {
        return COLOR_NEUTRAL;
    }

    return defaultColors.find(
        (color) => color.id === id,
    );
};

export default defaultColors;
