import { useSession } from 'next-auth/react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useSocket } from './socket';
import GameState from '../../src/enum/gamestate';
import { GameSocketMessageEventTypes, GameSockeNotifytMessageTypes, IGameSocketNotifyMessage } from '../../src/models/game/socket';
import { gameGetGames } from '../../src/thunks/game';
import { ApiGame } from '../../src/types/apigame';

export type ActiveGamesProviderValue = {
    isLoading: boolean;
    gameIds: number[];
}

const ActiveGamesProviderContext = createContext<ActiveGamesProviderValue>({
    isLoading: true,
    gameIds: [],
});

const isUserActive = (game: ApiGame, userId: number): boolean => {
    return userId != null
        && game.activeSeats?.some(
            (seatObject) => seatObject.userId === userId,
        );
}

export function ActiveGamesProvider({ children }) {
    const { socket } = useSocket();
    const { data: session, status } = useSession();
    const userId = status === 'authenticated' ? session.user.id : null;

    const [gameIds, setGameIds] = useState<Array<number>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const addGameId = useCallback(
        (gameIdToAdd: number): void => {
            if (gameIds.includes(gameIdToAdd)) {
                return;
            }

            setGameIds([...gameIds, gameIdToAdd]);
        },
        [gameIds, setGameIds],
    );

    const removeGameId = useCallback(
        (gameIdToRemove: number): void => {
            setGameIds(
                gameIds.filter(
                    (existingGameId) => existingGameId !== gameIdToRemove,
                ),
            );
        },
        [gameIds, setGameIds],
    );

    const getGames = useCallback(
        async () => {
            setIsLoading(true);

            try {
                const games = await gameGetGames();
                const gIds = [];

                for (const game of games) {
                    if (game.state === GameState.Complete) {
                        continue;
                    }

                    if (isUserActive(game, userId)) {
                        gIds.push(game.id);
                    }
                }

                setGameIds(gIds);
            } catch (err) {
                console.error('Error loading games', err);
            } finally {
                setIsLoading(false);
            }
        },
        [userId],
    );

    useEffect(
        () => {
            if (userId) {
                getGames();
            }
        },
        [getGames, userId],
    );

    useEffect(
        () => {
            if (!socket) {
                return;
            }

            const onNotify = (message: IGameSocketNotifyMessage) => {
                switch (message.type) {
                    case GameSockeNotifytMessageTypes.Invited:
                    case GameSockeNotifytMessageTypes.Turn: {
                        const gameId = message.gameId;

                        addGameId(gameId);

                        break;
                    }

                    case GameSockeNotifytMessageTypes.DeclinedInvite:
                    case GameSockeNotifytMessageTypes.GameStarted:
                    case GameSockeNotifytMessageTypes.Joined:
                    case GameSockeNotifytMessageTypes.TurnEnded: {
                        const gameId = message.gameId;

                        removeGameId(gameId);

                        break;
                    }
                }
            };

            socket.on(GameSocketMessageEventTypes.Notify, onNotify);

            return () => {
                socket.off(GameSocketMessageEventTypes.Notify, onNotify);
            };
        },
        [socket, gameIds, addGameId, removeGameId],
    );

    const value = { gameIds, isLoading };

    return (
        <ActiveGamesProviderContext.Provider value={value}>
            {children}
        </ActiveGamesProviderContext.Provider>
    );
}

export function useActiveGamesProvider() {
    return useContext(ActiveGamesProviderContext);
}
