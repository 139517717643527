import { Dialog, DialogBody, Spinner, SpinnerSize } from "@blueprintjs/core";
import { useSocket } from "../global/socket";
import { useEffect, useState } from "react";
import { ClientRequests } from "../../src/enum/socket";
import { UserProfile } from "../../src/models/socket/profile";
import PlayerAvatar from "./avatar";
import UserProfileDialogComponent from "./userprofiledialogcomponent";
import { getThemeClass, useTheme } from "../global/theme";

type Props = {
    userId: number;
    onClose: () => void;
}

export default function UserProfileDialog(
    {
        userId,
        onClose,
    }: Props
) {
    const { socket } = useSocket()
    const { theme } = useTheme();
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [error, setError] = useState<string>(null);

    useEffect(
        () => {
            const effect = async () => {
                try {
                    const socketProfile = await socket.emitWithAck(
                        ClientRequests.GetUserProfile,
                        userId,
                    );

                    if (!socketProfile) {
                        setError('Error loading profile')
                    } else {
                        setProfile(socketProfile);
                    }
                } catch (err) {
                    console.error('Error loading profile', err);
                    setError('Error loading profile');
                }
            };

            effect();
        },
        [socket, setProfile, setError, userId],
    );

    let body: JSX.Element;
    if (error) {
        body = <div>Error loading profile</div>;
    } else if (!profile) {
        body = <Spinner size={SpinnerSize.LARGE} />;
    } else {
        body = <UserProfileDialogComponent profile={profile} userId={userId} />;
    }

    return (
        <Dialog
            className={getThemeClass(theme)}
            isOpen={true}
            onClose={onClose}
            style={{ minWidth: '640px'}}
            title='Profile'
        >
            <DialogBody>
                {body}
            </DialogBody>
        </Dialog>
    );
}
